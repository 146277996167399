import { ApplicationConfig } from '@/config';
import { BaseClientBlockchain } from './baseClientBlockchain';
import { SupplierModel } from '@/models/supplierModel';
import { QualityReportResponseModel } from '@/models/qualityReportResponseModel';
import { ProductQualityReportModel } from '@/models/productQualityReportModel';
import { ClientRequestFootPrintModel } from '@/models/clientRequestFootprintModel';
import { NotificationHelper } from "@/helpers/notificationHelper";
import i18n from '@/i18n';
import { YarnFootprintRequestModel, YarnFootprintResponseModel, WetProcessFootprintResponseModel, YarnModel } from '@/models/yarnFootprintModel';
import { WetProcessFootprintRequestModel } from '@/models/wetProcessFootprintModel';
import { DeleteDocRequestModel, FootprintDataResponseModel, FootprintDeadlineResponseModel, SaveFootprintDeadlineModel, ViewFootprintResponseModel, FootprintCompletedResponseModel} from '@/models/footprintModel';
import { FabricFootprintRequestModel } from '@/models/fabricFootprintModel';
import { GarmentFootprintModel, GarmentFootprintRequestModel } from '@/models/garmentFootprintModel';
import { FootprintPartnerResponseModel } from '@/models/orderLineStepDisplayModel';
import { PublishUnpublishModel, updatePhaseStatusRequestModel, updatePhaseStatusResponseModel } from '@/models/publishUnpublishOrderModel';
import { DeleteOrderRequestModel } from '@/models/deleteOrderRequestModel';

export class SupplierBlockchain extends BaseClientBlockchain {
    private readonly endPoint: string = "v1/api/supplier";
    private readonly endPointClient: string = "v1/api/client";
    private readonly endPointOrder: string = "v1/api/order";

    public constructor() {
        super(ApplicationConfig.endpoints.blockchainApi);
    }

    public async getSustainableCertsAsync(supplierId: string): Promise<SupplierModel> {
        return await this.get(`${this.endPoint}/${supplierId}/pdf/getSustainable`);
    }

    public async getSocialCertsAsync(supplierId: string): Promise<SupplierModel> {
        return await this.get(`${this.endPoint}/${supplierId}/pdf/getSocial`);
    }

    public async uploadQualityReportAsync(uploadData: FormData, clientId: string): Promise<QualityReportResponseModel> {
        return await this.postWithResponse(`${this.endPointClient}/${clientId}/pdf/qualityReport`, uploadData, true);
    }

    public async getQualityReportsAsync(clientId: string, supplierId: string): Promise<QualityReportResponseModel>{
        return await this.get<QualityReportResponseModel>(`${this.endPointClient}/${clientId}/supplier/${supplierId}/pdf/getProductQualityReport`);
    }

    public async linkScpQualityReportAsync(orderId: string, lineId: string, data: ProductQualityReportModel[]): Promise<QualityReportResponseModel>{
        return await this.put(`${this.endPointOrder}/${orderId}/line/${lineId}/link/productQualityReport`, data);
    }

    public async deleteQualityReportScpAsync(clientId: string, reportId: string): Promise<Response>{
        const response = await this.delete(`${this.endPointClient}/${clientId}/pdf/qualityReport/${reportId}`);
        NotificationHelper.createSucceededNotification(i18n.t('pages.supply_chain_partners.delete_report_success').toString());
        return response;
    }

    public async requestFootprintData(orderId: string, data:[]): Promise<ClientRequestFootPrintModel>{
        return await this.patchWithResponse<ClientRequestFootPrintModel>(`${this.endPointOrder}/${orderId}/setFootprintFlag`, data, false);
    }

    public async saveFootprintDeadline(orderId: string, data: SaveFootprintDeadlineModel): Promise<FootprintDeadlineResponseModel>{
        const response =  await this.patchWithResponse<FootprintDeadlineResponseModel>(`${this.endPointOrder}/${orderId}/setFootprintDeadline`, data, false);
        if(response.success){
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_deadline_saved').toString());
            return response;
        }
        else{
            NotificationHelper.createErrorNotification(i18n.t('global.notifications.footprint_deadline_failed').toString());
            return response;
        }
    }

    public async saveYarnFootprintDataAsync(orderId: string, data: YarnFootprintRequestModel): Promise<YarnFootprintResponseModel>{
        return await this.patchWithResponse<YarnFootprintResponseModel>(`${this.endPointOrder}/${orderId}/setYarnFootprint`, data, false);
    }

    public async saveWetProcessDataAsync(orderId: string, data: WetProcessFootprintRequestModel): Promise<WetProcessFootprintResponseModel>{
        return await this.patchWithResponse<WetProcessFootprintResponseModel>(`${this.endPointOrder}/${orderId}/addWPFootprint`, data, false);
    }

    public async uploadSupportingDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        return await this.postWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/supportingdocs`, uploadData, true);
    }

    public async uploadWetMsdsDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        return await this.postWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/msdsdocs`, uploadData, true);
    }

    public async getFootprintOnOrderAsync(orderId: string): Promise<ViewFootprintResponseModel>{
        return await this.get<ViewFootprintResponseModel>(`${this.endPointOrder}/${orderId}/getFootprint`);
    }

    public async saveYarnTransportAsync(orderId: string, data: any): Promise<FootprintDataResponseModel>{
        return await this.patchWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/addTransport`, data, false);
    }

    public async deleteSupportDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel> {
        return await this.deleteWithData(`${this.endPointOrder}/${orderId}/deleteSupportingDocs`, data);
    }

    public async saveFabricFootprintDataAsync(orderId: string, data: FabricFootprintRequestModel): Promise<FootprintDataResponseModel>{
        return await this.patchWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/addFabricFootprint`, data, false);
    }

    public async deleteMsdsDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel> {
        return await this.deleteWithData(`${this.endPointOrder}/${orderId}/deleteMsdsDocs`, data);
    }

    public async saveGarmentFootprintDataAsync(orderId: string, data: GarmentFootprintRequestModel): Promise<FootprintDataResponseModel>{
        return await this.patchWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/addGarmentFootprint`, data, false);
    }

    public async getFootprintPartnerAsync(orderId: string, lineId: string): Promise<FootprintPartnerResponseModel>{
        return await this.get<FootprintPartnerResponseModel>(`${this.endPointOrder}/${orderId}/getSCPAsscociatedWithSteps`);
    }

    public async updatePublishOrderlinePhaseStatus(orderId: string, data: PublishUnpublishModel): Promise<updatePhaseStatusResponseModel> {
        return await this.patchWithResponse<updatePhaseStatusResponseModel>(`${this.endPointOrder}/${orderId}/updatePhaseAndStatus`, data, false);
    }

    public async updateOrderlinePhaseStatus(orderIds: updatePhaseStatusRequestModel, phase: number, status: number): Promise<updatePhaseStatusResponseModel> {
        return await this.postWithResponse<updatePhaseStatusResponseModel>(`${this.endPointClient}/order/phase/${phase}/status/${status}/update`, orderIds, false);
    }

    public async uploadYarnWetMsdsDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        return await this.postWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/msdsdocsYarnWp`, uploadData, true);
    }

    public async deleteYarnWetMsdsDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel> {
        return await this.deleteWithData(`${this.endPointOrder}/${orderId}/deleteMsdsdocsYarnWp`, data);
    }

    public async uploadSupportingWetYarnDocsAsync(orderId: string, uploadData: FormData): Promise<FootprintDataResponseModel>{
        return await this.postWithResponse<FootprintDataResponseModel>(`${this.endPointOrder}/${orderId}/supportingdocsYarnWp`, uploadData, true);
    }

    public async deleteWetYarnSupportDocs(orderId: string, data: DeleteDocRequestModel): Promise<FootprintDataResponseModel> {
        return await this.deleteWithData(`${this.endPointOrder}/${orderId}/deleteSupportingDocsYarnWp`, data);
    }
    //Sprint-21 TTD-3739 complete footprint orders
    public async completeFootprintOrders(data: DeleteOrderRequestModel): Promise<FootprintCompletedResponseModel> {
        return this.put(`${this.endPointOrder}/footprint/forceCompleted`, data);
    }
    //Sprint-21 TTD-3739 delete footprint orders
    public async deleteFootprintOrders(data: DeleteOrderRequestModel): Promise<FootprintCompletedResponseModel> {
        return this.put(`${this.endPointOrder}/footprint/deleteOrderLinesFromFootprintTab`, data);
    }
    //Sprint-21 TTD-4212 complete footprint orders
    public async setBackToInprogressFootprintOrders(data: DeleteOrderRequestModel): Promise<FootprintCompletedResponseModel> {
        return this.put(`${this.endPointOrder}/footprint/forceInProgress `, data);
    }
}