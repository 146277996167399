import { Guid } from "./guid";

export class AddressModel {
    public id!: string;
    public line1!: string;
    public line2!: string;
    public city!: string;
    public country!: string;
    public countryInFull!: string;
    public state!: string;
    public postal!: string;
    public disabled!: boolean;
    public lookupAvailable!: boolean;
    public isOfficeAddress!: boolean;
    public geoLat!: number;
    public geoLong!: number;

    public constructor() {
        this.id = Guid.Empty;
        this.line1 = "";
        this.line2 = "";
        this.city = "";
        this.country = "";
        this.countryInFull = "";
        this.state = "";
        this.postal = "";
        this.geoLat = 0;
        this.geoLong = 0;
        this.isOfficeAddress = false;
        this.lookupAvailable = false;
    }
}