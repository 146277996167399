import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import lodash from "lodash";
import { mapState } from 'vuex';

// Services
import { SupplierService } from "@/services/supplierService";

// Helpers
import { SuppliersHelper } from "@/helpers/suppliersHelper";

// Models
import { SupplierModel } from "@/models/supplierModel";
import { ContactModel } from "@/models/contactModel";
import { AddressModel } from "@/models/addressModel";
import { SupplierOverviewModel } from "@/models/supplierOverviewModel";
import { CPSCPModel, SupplierPartnerModel, UniqueSupplierWithStepModel } from "@/models/supplierPartnerModel";
import { CompanyPhotoModel } from "@/models/companyPhotoModel";
import { ComplianceDataModel } from "@/models/complianceDataModel";
import { CountryListModel } from "@/models/countryLanguageModel";
import { SupplierPartnersBrandsModel } from "@/models/brandWithSupplierModel";

@Component({
  computed: mapState(['countryList', 'supplierPartnersBrands', 't1AssociatedPartners', 'agentAssociatedPartners'])
})
export default class SupplyChainPartnerDetails extends Vue {

  @Prop()
  private supplier!: SupplierModel;

  @Prop()
  private selectedPartnerType!: number;

  private supplierService: SupplierService;

  private selectedUploads: string = "photos";

  private partners: SupplierPartnerModel[] = [];

  private partnersLoading: boolean = false;

  private countryList!: CountryListModel[];

  private supplierPartnersBrands!: SupplierPartnersBrandsModel;

  private t1AssociatedPartners!: CPSCPModel[];

  private agentAssociatedPartners!: CPSCPModel[];

  public constructor() {
    super();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {
    try {
      if(this.supplier.companyType === 5){
        this.supplier.factoryAddresses.forEach((address) => {
          address.city = '-';
        })
      }
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.partnersLoading = true;
      const associatedPartners: SupplierPartnerModel[] = [];
      let uniqPartners: UniqueSupplierWithStepModel[] = [];
      let allPartners: CPSCPModel[] = [];
      // TTD-3978: show suppliers associated with agent
      if(this.selectedPartnerType === 2){
        uniqPartners = SuppliersHelper.getAgentRelatedSuppliers(this.supplier.id);
        allPartners = this.agentAssociatedPartners.filter(ap => ap.companyID === this.supplier.id);
      } else {
        // TTD-3916: show suppliers based on client and cp
        uniqPartners = SuppliersHelper.getCPRelatedSuppliers(this.supplier.id);
        allPartners = this.t1AssociatedPartners.filter(ap => ap.companyID === this.supplier.id);
      }
      if(allPartners.length > 0){
        allPartners[0].suppliers.forEach(sup => {
          const partners = uniqPartners.filter(ap => ap.supplierId === sup.invitedCompanyID);
          if(partners.length > 0){
            const ap: SupplierPartnerModel = {
              partnerName: sup.companyName,
              companySubtypes: partners[0].steps,
              ID: sup.invitedCompanyID,
              onboarded: sup.onboarded
            }
            associatedPartners.push(ap);
          }
        })
      }
      this.partners = associatedPartners;
      // if(this.supplierPartnersBrands !== null){
      //   let associatedpartners: SupplierPartnerModel[] = [];
      //     const partners = this.supplierPartnersBrands.associatedPartners.filter(ap => ap.invitedByCompanyID === this.supplier.id && ap.id !== this.supplier.id);
      //     if(partners.length > 0){
      //       partners.forEach(p => {
      //         const ap: SupplierPartnerModel = {
      //           partnerName: p.name,
      //           companySubtypes: p.companySubType,
      //           ID: p.id
      //         }
      //         associatedpartners.push(ap);
      //       })
      //     }
      //   this.partners = associatedpartners;
      // } else {
      //   this.partners = await this.supplierService.getSupplierPartnersAsync(this.supplier.id);
      //   this.partners = this.partners.filter(p => p.ID !== this.supplier.id);
      //   if(this.partners.length > 0){
      //     const associatedpartners: SupplierPartnerModel[] = [];
      //     let suppliers: string[] = [];
      //     // TTD-3978: show suppliers associated with agent
      //     if(this.selectedPartnerType === 2){
      //       suppliers = SuppliersHelper.getAgentRelatedSuppliers(this.supplier.id);
      //     } else {
      //       // TTD-3916: show suppliers based on client and cp
      //       suppliers = SuppliersHelper.getCPRelatedSuppliers(this.supplier.id);
      //     }
      //     this.partners.forEach(partner => {
      //       const isIncluded = lodash.includes(suppliers, partner.ID, 0);
      //       if(isIncluded){
      //         associatedpartners.push(partner);
      //       }
      //     });
      //     this.partners = associatedpartners;
      //   }
      // }
    } finally {
      this.partnersLoading = false;
    }
  }

  private get partnersSorted(): SupplierPartnerModel[] {
    return this.partners.sort((partner1, partner2) => {
        if (partner1.partnerName < partner2.partnerName) {
            return -1;
        }
        if (partner1.partnerName > partner2.partnerName) {
            return 1;
        }
        return 0;
    });
  }

  private get selectedUploadText(): string {
    if (this.selectedUploads === "photos") {
      return this.$t("pages.supply_chain_partners.company_photos").toString();
    }
    return this.$t("pages.supply_chain_partners.certificates").toString();
  }

  private get contact(): ContactModel {
    return this.supplier.admin;
  }

  private get photos(): CompanyPhotoModel[] {
    return this.supplier.profilePictures;
  }

  private get certificates(): ComplianceDataModel[] {
    const socialCertificates =
      this.supplier.social
        .map(item => new ComplianceDataModel (
          item.id,
          item.issuer,
          item.referenceIssuer,
          item.type,
          item.validToDate,
          this.$t(`enums.social_certificates.social.${item.type}`).toString(),
          this.$t("pages.supply_chain_partners.social").toString()
        ));

    const sustainabilityCertificates =
      this.supplier.sustainability
      .map(item => new ComplianceDataModel (
        item.id,
        item.issuer,
        item.referenceIssuer,
        item.type,
        item.validToDate,
        this.$t(`enums.sustainability_certificates.sustainability.${item.type}`).toString(),
        this.$t("pages.supply_chain_partners.sustainable").toString()
      ));

    const combined = socialCertificates.concat(sustainabilityCertificates);
    return combined;
  }

  private get officeAddress(): AddressModel | null {
    if (this.supplier.officeAddress == null) {
      return null;
    }

    return this.supplier.officeAddress[0]!;
  }

  private get productGroups(): string {
    if (this.supplier.productGroups.length === 0) {
      return "-";
    }
    const productGroupNames = lodash.uniq(this.supplier.productGroups).map(p => this.$t(`enums.product_group.${p}`).toString().charAt(0).toUpperCase() + this.$t(`enums.product_group.${p}`).toString().slice(1).split(/(?=[A-Z])/).join(" ").toLowerCase()).join(", ");
    return productGroupNames.split(/(?=[A-Z])/).join(" ");
  }

  private get categories(): string {
    if (this.supplier.categories.length === 0) {
      return "-";
    }

    return lodash.uniq(this.supplier.categories).map(c => this.$t(`enums.category.${c}`).toString()).join(", ");
  }

  private get companySubtypes(): string {
    if (this.supplier.companySubtypes.length === 0) {
      return "-";
    }

    return lodash.uniq(this.supplier.companySubtypes).map(c => this.$t(`enums.steps.${c}`).toString()).join(", ");
  }

  private get companyType(): string {
    if (this.supplier.companyType === undefined || this.supplier.companyType === null || this.supplier.companyType === 0) {
      return "-";
    }

    return this.$t(`enums.company_type.${this.supplier.companyType}`).toString();
  }

  private get productionCountry(): string {
    let countryName = '';
    if (this.supplier.officeAddress == null || this.supplier.officeAddress[0].country === "") {
      if (this.supplier.factoryAddresses.length === 0) {
        return "-";
      }
      if(this.supplier.factoryAddresses[0].country.length === 2){
        countryName = this.$t(`countries.${this.supplier.factoryAddresses[0].country}`).toString();
      } else {
        const country = this.countryList.filter(c => c.name.toLowerCase() === this.supplier.factoryAddresses[0].country.toLowerCase())[0];
        if(country){
          countryName = this.$t(`countries.${country.iso2}`).toString();
        } else {
          countryName = this.supplier.factoryAddresses[0].country.toString();
        }
      }
      return countryName;
    }
    if (this.supplier.officeAddress[0].country.length === 2){
      countryName = this.$t(`countries.${this.supplier.officeAddress[0].country}`).toString();
    } else {
      const officeAddress = this.supplier.officeAddress[0];
      if(officeAddress !== null && officeAddress !== undefined){
        const country = this.countryList.filter(c => c.name.toLowerCase() === officeAddress.country.toLowerCase())[0];
        if(country){
          countryName = this.$t(`countries.${country.iso2}`).toString();
        } else {
          countryName = officeAddress.country.toString();
        }
      }
    }
    return countryName;
  }

  private getTypeText(type: number): string {
    return this.$t(`enums.steps.${type}`).toString();
  }

  private toggleUploadType(): void {
    this.selectedUploads = this.selectedUploads === "photos" ? "certificates" : "photos";
  }

  private openScpDetails(partner: any, e: any): void {
    e.stopPropagation();
    let scpPartner:any;
     scpPartner = {
      id: partner.ID,
      name: partner.partnerName
    };
    this.openDetails(scpPartner);
  }

  @Emit()
  private openDetails(supplier: SupplierOverviewModel): void {
  }
}
