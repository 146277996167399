import { OrderClient } from '@/clients/orderClient';
import { ClientClient } from '@/clients/clientClient';
import { ClientBlockchain } from '@/clients/clientBlockchain';
import { ClientBlockchainV2 } from '@/clients/clientBlockchainV2';
import { FileModel } from '@/models/fileModel';
import { OrderModel, OrderPictureQualityReportModel } from '@/models/orderModel';
import { NotificationHelper } from '@/helpers/notificationHelper';
import i18n from '@/i18n';
import store from '@/store';
import { CustomError } from '@/models/customError';
import { OrderLineStepModel } from '@/models/orderLineStepModel';
import moment from 'moment';
import { OrderLineStepStatus } from '@/models/orderLineStepStatus';
import { OrderLineStepDisplayModel, OrderLineStepResponseModel } from '@/models/orderLineStepDisplayModel';
import { ConfirmOrderModel } from '@/models/confirmOrderModel';
import { OrdersWithStepsModal } from "@/models/ordersWithStepsModel";
import { ProductFootprintUploadResultModel } from "@/models/productFootprintUploadResultModel";
import { CompanyModel } from '@/models/companyModel';
import { TopBrandModel } from '@/models/topBrandModel';
import { UserModel } from '@/models/userModel';
import { pluck } from 'underscore';
import { SocialEffortResponseModel } from '@/models/socialEffortModal';
import { Base64 } from 'js-base64';
import { DeleteOrderRequestModel, DeleteOrderResponseModel } from '@/models/deleteOrderRequestModel';
import { EditOrderModel, EditOrderResponseModel } from '@/models/bulkOrderModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { TcResponseModel } from '@/models/tcResponseModel';

export class OrderService {
    private readonly client: OrderClient = new OrderClient();
    private readonly companyClient: ClientClient = new ClientClient();
    private readonly clientBlockchain: ClientBlockchain = new ClientBlockchain();
    private readonly clientBlockchainV2: ClientBlockchainV2 = new ClientBlockchainV2();

    public async getOrdersAsync(clientId: string, bookmark: string, pageSize: number): Promise<OrdersWithStepsModal> {
        if(store.getters.companyType === 5){
            return this.agentDashboardAsync(clientId);
        }
        let newBrandsList = '';
        let result: OrdersWithStepsModal = new OrdersWithStepsModal;
        newBrandsList = await this.getBrandsList();
        result = await this.clientBlockchainV2.getOrdersAsync(clientId, bookmark, pageSize, newBrandsList);
        // result = await this.clientBlockchain.getOrdersAsync(clientId, bookmark, pageSize, newBrandsList);
        if(result.orders != null){
            result.orders.forEach(order => {
                // Initializing the properties so Vue picks up changes
                order.scpCompanyName = "";
                order.evidenceCreatedBy = "",
                order.evidenceCreatedAt = moment();
                order.stepStatus = OrderLineStepStatus.None;
                order.show = true;
                order.isOrderSeleted = false;
                order.expectedTimeOfDelivery = moment(order.expectedTimeOfDelivery);
            });
        }
        return result;
    }

    public async agentDashboardAsync(clientId: string): Promise<OrdersWithStepsModal> {
        return await this.clientBlockchain.getOrdersForAgentAsync(clientId);
    }

    public async getOrderLineStepsAsync(orderId: string, orderLineId: string): Promise<OrderLineStepModel[]> {
        const result = await this.client.getOrderLineStepsAsync(orderId, orderLineId);
        if(result.length > 0){
            const steps = store.getters.orderLineSteps as OrderLineStepDisplayModel[];
            const filteredSteps = steps.filter(s => s.orderId === orderId);
            filteredSteps.forEach(f => {
                const index = steps.findIndex(s => s.id === f.id);
                if(index !== -1){
                    steps.splice(index, 1);
                }
            });
            steps.push(...result);
            store.commit('setAllOrderlineSteps', steps);
        }
        return result;
    }

    public async getPendingOrdersAsync(): Promise<OrderModel[]> {
        const newBrandsList = await this.getBrandsList();
        const result = await this.client.getPendingOrdersAsync(newBrandsList);
        store.commit("setPendingOrders", result);
        return result;
    }

    public async uploadOrderCsvAsync(file: FileModel): Promise<OrdersWithStepsModal> {
        const newBrandsList = await this.getBrandsList();
        const result = await this.client.uploadOrderCsvAsync(file, newBrandsList);
        if (result.orders && result.orders.length>0) {
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.csv_upload_succeeded').toString());
        }
        else if (result.exceptions && result.exceptions.length > 0) {
            result.exceptions.forEach((error: CustomError) => {
                if(error.parameters.length > 0) {
                    NotificationHelper.createErrorNotification(`${error.description}`);
                } else if(error.description=="Unexpected error occurred") {
                    NotificationHelper.createErrorNotification(i18n.t('pages.home.orderline_error').toString());
                } else{
                    // In case of unknown error we want to know what's wrong
                    NotificationHelper.createErrorNotification(i18n.t(error.description).toString());
                }
            });
        }

        return result;
    }

    public async uploadFootprintCsvAsync(file: FileModel): Promise<ProductFootprintUploadResultModel> {
        const result = await this.client.uploadFootprintCsvAsync(file);
        if (result.succeeded) {
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.footprint_upload_succeeded').toString());
        }
        else if (result.exceptions.length > 0) {
            result.exceptions.forEach((error: CustomError) => {
                if(error.parameters.length > 0) {
                    NotificationHelper.createErrorNotification(i18n.t(error.code, error.parameters).toString());
                }
                else{
                    // In case of unknown error we want to know what's wrong
                    NotificationHelper.createErrorNotification(i18n.t(error.description).toString());
                }
            });
        }

        return result;
    }

    public async confirmOrderAsync(orders: ConfirmOrderModel[]): Promise<OrdersWithStepsModal> {
        // await Promise.all(orders.map(o => this.client.confirmOrderAsync(o)));
        const response = await this.client.confirmOrderAsync(orders);
        NotificationHelper.createSucceededNotification(i18n.t('global.notifications.order_lines_update_succeeded').toString());
        return response;
    }

    // Sprint 24, TTD-4471
    public async confirmTcAsync(data: object): Promise<TcResponseModel> {
        // await Promise.all(orders.map(o => this.client.confirmOrderAsync(o)));
        const response = await this.clientBlockchain.confirmTcAsync(data);
        if(response.success){
            NotificationHelper.createSucceededNotification('Transaction certificate successfully requested.');
        }
        else{
            NotificationHelper.createErrorNotification('An error occured while updating orders, please try again!');
        }
        return response;
    }

    public async deleteOrderAsync(data: DeleteOrderRequestModel): Promise<DeleteOrderResponseModel> {
        const result = await this.clientBlockchain.deleteOrderAsync(data);
        if (result.success) {
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.order_lines_delete_succeeded').toString());
        } else {
            NotificationHelper.createErrorNotification('An error occured while deleting orders, please try again!');
        }
        return result;
    }

    public async publishOrderAsync(orderId: string, lineId: string): Promise<void> {
        await this.client.publishOrderAsync(orderId, lineId);
        NotificationHelper.createSucceededNotification(i18n.t('global.notifications.publish_succeeded').toString());
    }

    public async unpublishOrderAsync(orderId: string, lineId: string): Promise<void> {
        await this.client.unpublishOrderAsync(orderId, lineId);
        NotificationHelper.createSucceededNotification(i18n.t('global.notifications.unpublish_succeeded').toString());
    }

    public async getScpOrdersCountAsync(supplierId: string): Promise<any> {
        const result = await this.client.getScpOrdersCountAsync(supplierId);
        return result;
    }

    public async createSupplyChainStepsAsync(orderLineStep: OrderLineStepDisplayModel[]): Promise<OrderLineStepResponseModel> {
        const response = await this.client.createSupplyChainStepsAsync(orderLineStep);
        // NotificationHelper.createSucceededNotification(i18n.t('global.notifications.supply_chain_creation_succeeded').toString());
        return response;
    }

    public async editSupplyChainStepsAsync(orderLineStep: OrderLineStepDisplayModel[]): Promise<boolean> {
        const result = await this.client.editSupplyChainStepsAsync(orderLineStep);
        if(result.orderLineSteps.length > 0){
            if (store.getters.companyType === 5) {
                const allSteps = store.getters.orderLineSteps as OrderLineStepDisplayModel[];
                result.orderLineSteps.forEach(s => {
                    const index = allSteps.findIndex(o => o.id === s.id);
                    if (index !== -1) {
                        allSteps.splice(index, 1, s); // updated response on same index
                    } else {
                        allSteps.push(s);
                    }
                    
                });
                store.commit('setAllOrderlineSteps', allSteps);
            } else {
                const allSteps = store.getters.orderLineSteps as OrderLineStepDisplayModel[];
                const steps = allSteps.filter(s => s.orderId !== result.orderLineSteps[0].orderId);
                steps.push(...result.orderLineSteps);
                store.commit('setAllOrderlineSteps', steps);
            }
            NotificationHelper.createSucceededNotification(i18n.t('global.notifications.supply_chain_update_succeeded').toString());
            return true;
        }
        else{
            return false;
        }
    }

    // Servie method to get orderline steps for all orders
    public async getAllStepsAsync(clientId: string): Promise<OrderLineStepResponseModel> {
        const result = this.clientBlockchainV2.getAllStepsAsync(clientId);
        // const result = this.clientBlockchain.getAllStepsAsync(clientId);
        return result;
    }
    
    // Service method to initaite getSocialEffort for dashboard
    public async getSocialEffort(supplierIds: string[], clientId: string): Promise<SocialEffortResponseModel> {
        const result = this.clientBlockchain.getSocialEffort(supplierIds, clientId);
        return result;
    }

    public async updateOrdersAsync(clientId: string, data: EditOrderModel[]): Promise<EditOrderResponseModel>{
        const result = await this.clientBlockchain.updateOrdersAsync(clientId, data);
        if(result.success){
            NotificationHelper.createSucceededNotification('Orders updated successfully.');
            const orders = store.getters.orders as OrderLineDisplayModel[];
            result.orders.forEach((order, index) => {
            const orderIndex = orders.findIndex(o => o.orderId === order.orderId);
            if(orderIndex !== -1){
                orders[orderIndex].styleName = order.styleName;
                orders[orderIndex].orderLines[0].colourway = order.orderLines[0].colourway;
                orders[orderIndex].orderLines[0].season = order.orderLines[0].season;
                orders[orderIndex].orderLines[0].compositionMainFabric = order.orderLines[0].compositionMainFabric;
            }
            });
            store.commit('setAllOrders', orders);
        }
        else{
            NotificationHelper.createErrorNotification('An error occured while updating orders, please try again!');
        }
        return result;
    }

    public async getPictureQualityReportAsync(orderId: string): Promise<OrderPictureQualityReportModel>{
        const result = await this.clientBlockchain.getPictureQualityReportAsync(orderId);
        if(result.success){
            const orders = store.getters.orders as OrderLineDisplayModel[];
            const orderIndex = orders.findIndex(o => o.orderId === orderId);
            if(orderIndex !== -1){
                orders[orderIndex].productPictures = result.productPictureUrls;
                orders[orderIndex].productQualityReport = result.productQualityReport;
            }
            store.commit('setAllOrders', orders);
        }
        else{
            NotificationHelper.createErrorNotification('An error occured while fetching product pictures, please try again!'); 
        }
        return result;
    }

    private async getBrandsList(): Promise<string> {
        const user = store.getters.user as UserModel;
        let brandsList: TopBrandModel[] = [];
        if(user.topBrands === null || user.topBrands === undefined || user.topBrands.length === 0){
            let company: CompanyModel = new CompanyModel();
            if(store.getters.company === null){
                const response = await this.companyClient.getClientAsync();
                company = response;
                store.commit('setMyAccount', company);
                store.commit('setProfile', company);
            }
            else{
                company = store.getters.company;
            }
            brandsList = company.topBrands;
        }
        else{
            brandsList = user.topBrands;
        }
        const brands = pluck(brandsList, "brandName");
        let queryBrands = "";
        brands.forEach(brand => {
            queryBrands += JSON.stringify(brand) + ',';
        });
        queryBrands = queryBrands.substring(0,queryBrands.length - 1);
        queryBrands = Base64.encode(queryBrands);
        return queryBrands;

    }
}
