export class CertificateModel {
  public ID!: string;
  public createdAt!: string;
  public description!: string;
  public docURL!: string;
  public fileName!: string;
  public fileType!: string;
  public geoLat!: number;
  public geoLong!: number;
  public issuer!: string;
  public referenceIssuer!: string;
  public validtoDate!: string;
  public type!: number;
  public oekoClass!: string;
  public rspHolder!: string;
  public auditResult!: string;
  public siteAmforiID!: string;
  public amforiID!: string;
  public otherCertName!: string;
  // TTD-4432 sustainbility
  public licenseID!: string;
  public femScore!: string;
  public membershipID!: string;
  public issuanceDate!: string;
  public certificateType!: string;
  public fslmScore!: string;
  public status!: string;
  public isShowArchive!: boolean;
  public relatableType!: string;
  public typeName!: string;
  public isArchiving!: boolean;
  public companyID!: string;
  public companyName!: string;
  public toCompanyID!: string;
  public expiryInfo!: string;
  public expiryStatus!: string;
}

export class CertificateResponseModel {
  public success!: boolean;
  public socialPdf!: CertificateModel[];
  public sustainablePdf!: CertificateModel[];
}

export class CertificateOnTypeModel {
  public constructor(id: string, name: string, type: string, relatedType: string) {
    this.ID = id;
    this.Name = name;
    this.Type = type;
    this.relatedType = relatedType;
  }
  public ID!: string;
  public Name!: string;
  public Type!: string;
  public relatedType!: string;
}

export class ArchiveResponse {
  public success!: boolean;
  public ID!: string;
  public certificateType!: string;
  public relatableType!: string;
  public status!: string;
}


export enum CertificateTypes {
  SOCIAL = 'SOCIAL',
  SUSTAINABLE = 'SUSTAINABLE',
  AUDIT = 'AUDIT',
  MEMBERSHIP = 'MEMBERSHIP',
  ARCHIVED = 'ARCHIVED',
  CERTIFICATE = 'CERTIFICATE',
  SUSTAINABILITY = 'SUSTAINABILITY',
  UPLOADED = 'UPLOADED'
}
