import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import store from '@/store';

// Models
import { UserModel } from '@/models/userModel';
import { UserPermissionModel } from '@/models/permissionModel';

// Services
import { UserClient } from '@/clients/userClient';
import { ActionTypes } from '@/store/actions/actions';

@Component({
    computed: mapState(['userRole', 'userPermissions', 'deletedOrderIds', 'companyType', 'isAgentPilot']),
})
export default class Sidebar extends Vue {
    @Prop()
    private sidebar!: boolean;

    private isCollapsed: boolean = false;

    private applicationLogo: NodeRequire = require('../../assets/logo.svg');

    private applicationLogoShort: NodeRequire = require('../../assets/logo-short.svg');

    private showOrdelines: boolean = true;

    private client: UserClient = new UserClient();

    private userRole!: string;

    private deletedOrderIds!: string[];

    private companyType!: number;

    private userPermissions!: UserPermissionModel | null;

    private isAgentPilot!: boolean;

    public constructor() {
        super();
    }

    private async created(): Promise<void> {
        const user = this.$store.getters.user as UserModel;
        if (user == null) {
        const result = await this.client.getUserInfoAsync();
        this.$store.commit('setUser', result);
        }
        this.isCollapsed =
        localStorage.getItem('collapsedSidebar') == 'true' ? true : false;
    }

    private get isAdmin(): boolean {
        let result = true;
        if(this.userRole === 'Editor' || this.userRole === 'Reader'){
            result = false;
        }
        else{
            result  = true;
        }
        return result;
    }

    private get isAgent(): boolean {
        let result = true;
        if(this.companyType !== 5){
            result = false;
        }
        return result;
    }

    private async getNonDeletedOrderData(): Promise<void> {
        if(this.deletedOrderIds.length > 0){
            store.dispatch(ActionTypes.SET_SUPPLIER_CERTIFICATE);
            await store.dispatch(ActionTypes.SET_ORDER_DETAIL);
            store.commit('setDeletedOrderIds', []);
        }
    }

    // Commented in TTD-3772, Sprint 19
    // private get isPilotPratner(): number {
    //     let isPilot = 1;
    //     if(this.companyType === 5 && !this.isAgentPilot){
    //         isPilot = 0;
    //     }
    //     return isPilot;
    // }
}
