import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import moment from "moment";
import i18n from "@/i18n";
import VuePdfApp from "vue-pdf-app";
import lodash from "lodash";
import { mapState } from "vuex";

// Components
import ViewQualityDocument from "@/views/supplyChainPartners/components/viewQualityDocument.vue";
import UploadQualityReport from "@/views/supplyChainPartners/components/uploadQualityReport.vue";
import ViewCertificatesUploaded from '@/views/supplyChainPartners/components/viewCertificatesUploaded.vue';

// Services
import { ComplianceService } from "@/services/complianceService";
import { SupplierService } from "@/services/supplierService";

// Modals
import { ComplianceDocSentResponseModel } from "@/models/complianceDocSentResponseModel";
import { SustainableCertificatesModel } from "@/models/sustainableCertificatesModel";
import { SocialCertificatesModel } from "@/models/socialCertificatesModel";
import { SustainableDocModel } from "@/models/SustainableDocModel";
import { SocialDocModel } from "@/models/socialDocModel";
import { QualityReportsDocModel } from "@/models/qualityReportsDocModel";
import { ProductQualityReportModel } from "@/models/productQualityReportModel";
import { UserModel } from "@/models/userModel";
import { SupplierModel } from "@/models/supplierModel";
import { DropdownModel } from "@/models/dropdownModel";
import { UserPermissionModel } from "@/models/permissionModel";
import {
  CertificateModel,
  CertificateOnTypeModel,
  CertificateTypes,
} from "@/models/certificateModel";

@Component({
  components: {
    VuePdfApp,
    UploadQualityReport,
    ViewQualityDocument,
	ViewCertificatesUploaded
  },
  computed: mapState(["userRole", "userPermissions", "companyType"]),
})
export default class Certificates extends Vue {
  @Prop()
  private documents!: {
    social: CertificateModel[];
    sustainable: CertificateModel[];
  };

  @Prop()
  private loadingDoc!: boolean;

  @Prop()
  private supplierId!: string;

  @Prop()
  private supplier!: SupplierModel;

  @Prop()
  private isloadingQualityReport!: boolean;

  @Prop()
  private qualityReports!: ProductQualityReportModel[];

  @Prop()
  private brands!: DropdownModel[];

  private pdfDocument!: any;

  private complianceService: ComplianceService;

  private showRSP: boolean = false;
  private showOeko: boolean = false;
  private showSusPDFModal: boolean = false;
  private showSocialPDFModal: boolean = false;
  private showPdfCommentsModal: boolean = false;
  private showReportPDFModal: boolean = false;

  private currentSustainableDocument!: SustainableCertificatesModel;

  private currentReportDocument!: ProductQualityReportModel;

  private currentSocialDocument!: SocialCertificatesModel;

  private docWithSustainable: SustainableDocModel[] = [];

  private docWithSocial: SocialDocModel[] = [];

  private allQualityReports: ProductQualityReportModel[] = [];

  private docWithQuality: QualityReportsDocModel[] = [];

  private sort: string = "desc";

  private sustainables: string[][] = [];

  private socials: [] = [];

  private sortIcon: NodeRequire = require("../../../assets/icons/general/desc-sort.svg");

  private ovalIcon: NodeRequire = require("../../../assets/icons/general/oval-orange.svg");

  private showUploadModal: boolean = false;

  private isReportDeleting: boolean = false;

  private isQualityReportPreparing: boolean = true;

  private supplierService: SupplierService;

  private issuersList: DropdownModel[] = [];

  private focusCertificateGroup: string = "";

  private userRole!: string;

  private userPermissions!: UserPermissionModel | null;

  private companyType!: number;

  private showPDFModal: boolean = false;
  private sortGroupType: string = "";
  private sortGroupTypename: string = "";
  private sortValidToLatest: boolean = true;
  private sortValidToLatestArchive: boolean = true;

  private susFocusCertificateGroup: string = "";
  private socFocusCertificateGroup: string = "";
  private auditFocusCertificateGroup: string = "";
  private memFocusCertificateGroup: string = "";

  private documentDetail: CertificateModel = new CertificateModel();

  private isShowArchive: boolean = false;

  private sustainableCerts: CertificateOnTypeModel[] = [];
  private auditCerts: CertificateOnTypeModel[] = [];
  private sustainableAuditCerts: CertificateOnTypeModel[] = [];
  private sustainableMemCerts: CertificateOnTypeModel[] = [];
  private socialCerts: CertificateOnTypeModel[] = [];
  private socailAuditCerts: CertificateOnTypeModel[] = [];
  private socailMemCerts:  CertificateOnTypeModel[] = [];
  private membershipCerts: CertificateOnTypeModel[] = [];
  private showSocialArchive: boolean = false;
  private showSustainableArchive: boolean = false;
  private showAuditArchive: boolean = false;
  private showMembershipArchive: boolean = false;

  public constructor() {
    super();
    this.complianceService = new ComplianceService();
    this.supplierService = new SupplierService();
  }

  private async created(): Promise<void> {
    const sustainable = i18n.t(
      `enums.sustainability_certificates.sustainability`
    ) as any;
    for (const key of Object.keys(sustainable)) {
      const certifcateName = sustainable[key];
      this.sustainableCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.SUSTAINABILITY,
          CertificateTypes.SUSTAINABILITY
        )
      );
    }
    const susMembership = i18n.t(
      `enums.sustainability_certificates.membership`
    ) as any;
    for (const key of Object.keys(susMembership)) {
      const certifcateName = susMembership[key];
      this.sustainableMemCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SUSTAINABILITY));
      this.membershipCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.MEMBERSHIP,
          CertificateTypes.SUSTAINABILITY
        )
      );
    }
    const susAudit = i18n.t(`enums.sustainability_certificates.audit`) as any;
    for (const key of Object.keys(susAudit)) {
      const certifcateName = susAudit[key];
      this.sustainableAuditCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SUSTAINABILITY));
      this.auditCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.AUDIT,
          CertificateTypes.SUSTAINABILITY
        )
      );
    }
    const social = i18n.t(`enums.social_certificates.social`) as any;
    for (const key of Object.keys(social)) {
      const certifcateName = social[key];
      this.socialCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.SOCIAL,
          CertificateTypes.SOCIAL
        )
      );
    }
    const socialAudit = i18n.t(`enums.social_certificates.audit`) as any;
    for (const key of Object.keys(socialAudit)) {
      const certifcateName = socialAudit[key];
      this.socailAuditCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.AUDIT, CertificateTypes.SUSTAINABILITY));
      this.auditCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.AUDIT,
          CertificateTypes.SOCIAL
        )
      );
    }
    const socialMembership = i18n.t(
      `enums.social_certificates.membership`
    ) as any;
    for (const key of Object.keys(socialMembership)) {
      const certifcateName = socialMembership[key];
      this.socailMemCerts.push(new CertificateOnTypeModel(key, certifcateName, CertificateTypes.MEMBERSHIP, CertificateTypes.SOCIAL));
      this.membershipCerts.push(
        new CertificateOnTypeModel(
          key,
          certifcateName,
          CertificateTypes.MEMBERSHIP,
          CertificateTypes.SOCIAL
        )
      );
    }
    // product quality report
    this.allQualityReports = this.qualityReports;
    this.isQualityReportPreparing = true;
    await this.getQualityReports();
    this.isQualityReportPreparing = false;
  }

  private async updateFocusCertificateGroup(
    group: string,
    type: string
  ): Promise<void> {
    if (type === "SUSTAINABILITY") {
      if (this.susFocusCertificateGroup !== group) {
        this.susFocusCertificateGroup = group;
      } else {
        this.susFocusCertificateGroup = "";
      }
    } else if (type === "SOCIAL") {
      if (this.socFocusCertificateGroup !== group) {
        this.socFocusCertificateGroup = group;
      } else {
        this.socFocusCertificateGroup = "";
      }
    } else if (type === "AUDIT") {
      if (this.auditFocusCertificateGroup !== group) {
        this.auditFocusCertificateGroup = group;
      } else {
        this.auditFocusCertificateGroup = "";
      }
    } else {
      if (this.memFocusCertificateGroup !== group) {
        this.memFocusCertificateGroup = group;
      } else {
        this.memFocusCertificateGroup = "";
      }
    }
  }

  private get socialCertificates(): {} {
    return this.filterDocuments("social");
  }

  private get sustainableCertificates(): {} {
    return this.filterDocuments("sustainable");
  }

  private get auditCertificates(): {} {
    return this.filterDocuments("audit");
  }

  private get memberShipCertificates(): {} {
    return this.filterDocuments("membership");
  }

  private get archivedSustainable(): {} {
    return this.filterDocuments("archiveSustainable");
  }
  private get archivedSocial(): {} {
    return this.filterDocuments("archiveSocial");
  }
  private get archivedAudit(): {} {
    return this.filterDocuments("archiveAudit");
  }
  private get archivedMembership(): {} {
    return this.filterDocuments("archiveMembership");
  }

  private sortByValidTo(type: number, typename: string): void {
    this.sortValidToLatest = !this.sortValidToLatest;
    this.sortGroupType = type.toString();
    this.sortGroupTypename = typename;
    this.uploadedCertificates(type, typename);
  }

  private sortByValidToArchive(type: number, typename: string): void {
    this.sortValidToLatestArchive = !this.sortValidToLatestArchive;
    this.sortGroupType = type.toString();
    this.sortGroupTypename = typename;
    this.showArchivedCertificates(type, typename);
  }

  private filterDocuments(name: string): {} {
    let documents: CertificateModel[] = [];
    if (name === 'social') {
      documents = this.documents.social.filter(s => s.certificateType !==CertificateTypes.AUDIT && s.certificateType !==CertificateTypes.MEMBERSHIP && s.type!==0 && this.socailAuditCerts.findIndex(au => au.ID === s.type.toString())===-1 && this.socailMemCerts.findIndex(mem => mem.ID === s.type.toString())===-1);
    } else if (name === 'audit') {
      documents = [...this.documents.social.filter(s => s.certificateType === CertificateTypes.AUDIT || (s.certificateType === '' && this.socailAuditCerts.findIndex(au => au.ID === s.type.toString())!==-1)), ...this.documents.sustainable.filter(s => s.certificateType === CertificateTypes.AUDIT || (s.certificateType === '' && this.sustainableAuditCerts.findIndex(au => au.ID === s.type.toString())!==-1))];
    } else if (name === 'membership') {
      documents = [...this.documents.social.filter(s => s.certificateType === CertificateTypes.MEMBERSHIP && s.membershipID!==''), ...this.documents.sustainable.filter(s => s.certificateType === CertificateTypes.MEMBERSHIP && s.membershipID!=='')];
    } else {
      documents = this.documents.sustainable.filter(s => s.certificateType !==CertificateTypes.AUDIT && s.certificateType !==CertificateTypes.MEMBERSHIP && s.type!==0 && this.sustainableAuditCerts.findIndex(au => au.ID === s.type.toString())===-1 && this.sustainableMemCerts.findIndex(mem => mem.ID === s.type.toString())===-1);
    }
    const app = this;
    const groupedCertificateModel = documents.reduce(
      (acc: any, certificate: any) => {
        const filteredCertificate = lodash.clone(certificate);
        if (name === "social") {
          const cert = this.socialCerts.filter(
            (soc) => soc.ID === certificate.type.toString()
          )[0];
          if (cert) {
            filteredCertificate.typeName = cert.Name;
          }
        } else if (name === "audit") {
          const cert = this.auditCerts.filter(
            (aud) => aud.ID === certificate.type.toString()
          )[0];
          if (cert) {
            filteredCertificate.typeName = cert.Name;
          }
        } else if (name === "membership") {
          const cert = this.membershipCerts.filter(
            (mem) => mem.ID === certificate.type.toString()
          )[0];
          if (cert) {
            filteredCertificate.typeName = cert.Name;
          }
        } else {
          const cert = this.sustainableCerts.filter(
            (sus) => sus.ID === certificate.type.toString()
          )[0];
          if (cert) {
            filteredCertificate.typeName = cert.Name;
          }
        }
        filteredCertificate.expiryInfo = "";
        filteredCertificate.expiryStatus = "";
        const daysDifference = moment(certificate.validtoDate).diff(
          moment(),
          "days"
        );
        if (daysDifference <= 30) {
          if (daysDifference > 0) {
            filteredCertificate.expiryInfo = `Expiring in ${daysDifference} days`
            if(daysDifference === 1){
              filteredCertificate.expiryInfo = `Expiring in ${daysDifference} day`
            }
          } else if (daysDifference == 0) {
            filteredCertificate.expiryInfo = "Expiring";
          } else {
            filteredCertificate.expiryInfo = `expired`
          }
          filteredCertificate.expiryStatus =
            daysDifference >= 0 ? `expiring` : `expired`;
        }
        (
          acc[filteredCertificate.typeName] ||
          (acc[filteredCertificate.typeName] = [])
        ).push(filteredCertificate);
        return acc;
      },
      {}
    );

    const orderedGroupedCertificateModel = Object.keys(groupedCertificateModel)
      .sort()
      .reduce((obj: any, key) => {
        obj[key] = groupedCertificateModel[key];
        return obj;
      }, {});
    return orderedGroupedCertificateModel;
  }

  private showArchivedCertificates(
    typeValue: number,
    type: string
  ): CertificateModel[] {
    if (type === CertificateTypes.SUSTAINABILITY) {
      let documents = this.documents.sustainable.filter(
        (s) =>
          s.type === typeValue &&
          s.status === CertificateTypes.ARCHIVED &&
          s.certificateType !== CertificateTypes.AUDIT &&
          s.certificateType !== CertificateTypes.MEMBERSHIP
      );
      documents = this.certificateStatus(documents);
      documents = this.sortByArchiveLatest(documents);
      return lodash.uniqBy(documents, "ID");
    } else if (type === CertificateTypes.SOCIAL) {
      let documents = this.documents.social.filter(
        (s) =>
          s.type === typeValue &&
          s.status === CertificateTypes.ARCHIVED &&
          s.certificateType !== CertificateTypes.AUDIT &&
          s.certificateType !== CertificateTypes.MEMBERSHIP
      );
      documents = this.certificateStatus(documents);
      documents = this.sortByArchiveLatest(documents);
      return lodash.uniqBy(documents, "ID");
    } else if (type === CertificateTypes.AUDIT) {
      const suAudit = this.documents.sustainable.filter(
        (s) =>
          s.type === typeValue &&
        (s.status === CertificateTypes.ARCHIVED || s.status === '') &&
        (s.certificateType === CertificateTypes.AUDIT || s.certificateType === '')
      );
      const socialAudit = this.documents.social.filter(
        (s) =>
          s.type === typeValue &&
        (s.status === CertificateTypes.ARCHIVED || s.status === '') &&
        (s.certificateType === CertificateTypes.AUDIT || s.certificateType === '')
      );
      let documents = [...suAudit, ...socialAudit];
      documents = this.certificateStatus(documents);
      documents = this.sortByArchiveLatest(documents);
      return lodash.uniqBy(documents, "ID");
    } else {
      const suAudit = this.documents.sustainable.filter(
        (s) =>
          s.type === typeValue &&
          s.status === CertificateTypes.ARCHIVED &&
          s.certificateType === CertificateTypes.MEMBERSHIP
      );
      const socialAudit = this.documents.social.filter(
        (s) =>
          s.type === typeValue &&
          s.status === CertificateTypes.ARCHIVED &&
          s.certificateType === CertificateTypes.MEMBERSHIP
      );
      let documents = [...suAudit, ...socialAudit];
      documents = this.certificateStatus(documents);
      documents = this.sortByArchiveLatest(documents);
      return lodash.uniqBy(documents, "ID");
    }
  }

  private formattedDate(date: string): string {
    return moment(date).format("D MMM YYYY");
  }

  private openPDFModal(doc: CertificateModel, certName: string): void {
    this.documentDetail = doc;
    this.documentDetail.typeName = certName;
    this.documentDetail.amforiID = doc.licenseID;
    this.showPDFModal = true;
  }

  private isStatusUploadedExist(certs: CertificateModel[]): boolean {
    if (
      certs.findIndex(
        (c) => c.status === CertificateTypes.UPLOADED || c.status === ""
      ) === -1
    ) {
      return false;
    }
    return true;
  }

  private uploadedCertificates(
    typeValue: number,
    type: string
  ): CertificateModel[] {
    if (type === CertificateTypes.SUSTAINABILITY) {
      let documents = this.documents.sustainable.filter(
        (s) =>
          s.type === typeValue &&
          (s.status === CertificateTypes.UPLOADED || s.status === "") &&
          s.certificateType !== CertificateTypes.AUDIT &&
          s.certificateType !== CertificateTypes.MEMBERSHIP
      );
      documents = this.certificateStatus(documents);
      documents = this.sortByValidToDate(documents);
      return lodash.uniqBy(documents, "ID");
    } else if (type === CertificateTypes.SOCIAL) {
      let documents = this.documents.social.filter(
        (s) =>
          s.type === typeValue &&
          (s.status === CertificateTypes.UPLOADED || s.status === "") &&
          s.certificateType !== CertificateTypes.AUDIT &&
          s.certificateType !== CertificateTypes.MEMBERSHIP
      );
      documents = this.certificateStatus(documents);
      documents = this.sortByValidToDate(documents);
      return lodash.uniqBy(documents, "ID");
    } else if (type === CertificateTypes.AUDIT) {
      const suAudit = this.documents.sustainable.filter(
        (s) =>
          s.type === typeValue &&
          (s.status === CertificateTypes.UPLOADED || s.status === "") &&
          s.certificateType === CertificateTypes.AUDIT
      );
      const socialAudit = this.documents.social.filter(
        (s) =>
          s.type === typeValue &&
          (s.status === CertificateTypes.UPLOADED || s.status === "") &&
          (s.certificateType === CertificateTypes.AUDIT ||
            (s.certificateType === "" &&
              this.auditCerts.findIndex(
                (au) => au.ID === typeValue.toString()
              ) !== -1))
      );
      let documents = [...suAudit, ...socialAudit];
      documents = this.certificateStatus(documents);
      documents = this.sortByValidToDate(documents);
      return lodash.uniqBy(documents, "ID");
    } else {
      const suAudit = this.documents.sustainable.filter(
        (s) =>
          s.type === typeValue &&
          (s.status === CertificateTypes.UPLOADED) &&
          (s.certificateType === CertificateTypes.MEMBERSHIP)
      );
      const socialAudit = this.documents.social.filter(
        (s) =>
          s.type === typeValue &&
          s.status === CertificateTypes.UPLOADED &&
          (s.certificateType === CertificateTypes.MEMBERSHIP ||
            s.certificateType === "")
      );
      let documents = [...suAudit, ...socialAudit];
      documents = this.certificateStatus(documents);
      documents = this.sortByValidToDate(documents);
      return lodash.uniqBy(documents, "ID");
    }
  }

  private sortByValidToDate(documents: CertificateModel[]): CertificateModel[] {
    const app = this;
    documents.sort(function(a: CertificateModel, b: CertificateModel) {
      const dateA = new Date(a.validtoDate),
        dateB = new Date(b.validtoDate);
      if (
        a.type.toString() == app.sortGroupType ||
        b.type.toString() == app.sortGroupType
      ) {
        return app.sortValidToLatest
          ? moment(dateB).unix() - moment(dateA).unix()
          : moment(dateA).unix() - moment(dateB).unix();
      }
      return moment(dateB).unix() - moment(dateA).unix();
    });
    return documents;
  }

  private sortByArchiveLatest(
    documents: CertificateModel[]
  ): CertificateModel[] {
    const app = this;
    documents.sort(function(a: CertificateModel, b: CertificateModel) {
      const dateA = new Date(a.validtoDate),
        dateB = new Date(b.validtoDate);
      if (
        a.type.toString() == app.sortGroupType ||
        b.type.toString() == app.sortGroupType
      ) {
        return app.sortValidToLatestArchive
          ? moment(dateB).unix() - moment(dateA).unix()
          : moment(dateA).unix() - moment(dateB).unix();
      }
      return moment(dateB).unix() - moment(dateA).unix();
    });
    return documents;
  }

  private closePDFModal(success: boolean): void {
    if (success) {
      this.reloadDocuments();
    }
    this.showPDFModal = false;
  }

  private toggleArchive(typeValue: number, relatableTtype:string, certificateType:string): void{
    const susIndex = this.documents.sustainable.findIndex(f => f.type === typeValue && f.certificateType === certificateType);
    const socIndex = this.documents.social.findIndex(f => f.type === typeValue && f.certificateType === certificateType);
    if(susIndex!==-1 && relatableTtype === CertificateTypes.SUSTAINABILITY){
      const cert = this.documents.sustainable.filter(s => s.type === typeValue && s.certificateType === certificateType)[0];
      cert.isShowArchive = !cert.isShowArchive;
      this.documents.sustainable.splice(susIndex, 0, cert);
    }
    if(socIndex!==-1 && relatableTtype === CertificateTypes.SOCIAL){
      const cert = this.documents.social.filter(s => s.type === typeValue && s.certificateType === certificateType)[0];
      cert.isShowArchive = !cert.isShowArchive;
      this.documents.social.splice(socIndex, 0, cert);
    }
  }

  private getNearestDate(cert: CertificateModel[]): string {
    let dates: Date[] = cert.filter(c => c.validtoDate && c.status !== CertificateTypes.ARCHIVED).map(c => new Date(c.validtoDate));
    if(dates.length === 0){
      dates = cert.filter(c => c.validtoDate && c.status === CertificateTypes.ARCHIVED).map(c => new Date(c.validtoDate));
    }
    const today = new Date();
    if(dates.filter(d => d > today).length > 1){
      const nearestDate = dates.filter(d => d > today).sort((a, b) => a.getDate() - b.getDate())[0];
      return this.formattedDate(nearestDate.toString());
    } else if(dates.filter(d => d > today).length === 1){
      const nearestDate = dates.filter(d => d > today)[0];
      return this.formattedDate(nearestDate.toString());
    } else {
      let nearestDate = dates[0];
      let minDifference = Math.abs(nearestDate.getTime() - today.getTime());
      if(dates.length > 1){
        for (let i = 1; i < dates.length; i++) {
          const difference = Math.abs(dates[i].getTime() - today.getTime());
          if (difference < minDifference) {
            nearestDate = dates[i];
            minDifference = difference;
          }
        }
      }
      return this.formattedDate(nearestDate.toString());
    }
  }

  private certificateStatus(
    certificates: CertificateModel[]
  ): CertificateModel[] {
    const app = this;
    certificates.forEach((certificate) => {
      const daysDifference = moment(certificate.validtoDate).diff(
        moment(),
        "days"
      );
      if (daysDifference <= 30) {
        if (daysDifference > 0) {
          certificate.expiryInfo = `Expiring in ${daysDifference} days`;
        } else if (daysDifference == 0) {
          certificate.expiryInfo = "Expiring";
        } else {
          certificate.expiryInfo = "Expired";
        }

        certificate.expiryStatus = daysDifference >= 0 ? `expiring` : `expired`;
      }
    });
    return certificates;
  }

  private async getQualityReports(): Promise<QualityReportsDocModel[]> {
    this.docWithQuality = [];
    this.issuersList = [];
    if (this.allQualityReports && this.allQualityReports.length > 0) {
      this.allQualityReports.forEach((doc) => {
        if (
          this.docWithQuality.findIndex((d) => d.issuer === doc.issuer) === -1
        ) {
          this.issuersList.push({ value: doc.issuer, text: doc.issuer });
          const document: QualityReportsDocModel = new QualityReportsDocModel();
          document.issuer = doc.issuer;
          document.allCerts = this.allQualityReports.filter(
            (d) => d.issuer === doc.issuer
          );
          document.allCerts = document.allCerts.sort(
            (a, b) => Date.parse(b.validToDate) - Date.parse(a.validToDate)
          );
          document.maxDate = moment(
            new Date(
              Math.max(
                ...document.allCerts.map((e) => Date.parse(e.validToDate))
              )
            )
          ).format("D MMM YYYY");
          this.docWithQuality.push(document);
        }
      });
    }
    return this.docWithQuality;
  }

  private async deleteReport(
    selectedReportDocument: ProductQualityReportModel
  ): Promise<void> {
    const confirmationText = this.$t(
      "pages.order_lines.report_delete_confirmation"
    ).toString();
    this.$confirm(confirmationText, undefined, undefined, {
      allowOutsideClick: false,
    })
      .then(async () => {
        this.isReportDeleting = true;
        const user = this.$store.getters.user as UserModel;
        await this.supplierService.deleteQualityReportScpAsync(
          user.companyId,
          selectedReportDocument.ID
        );
        const index = this.allQualityReports.findIndex(
          (r) => r.ID === selectedReportDocument.ID
        );
        this.allQualityReports.splice(index, 1);
        this.getQualityReports();
        this.isReportDeleting = false;
      })
      .catch((onrejected) => {
        return "";
      });
  }

  private openReportPdfModal(
    selectedReportDocument: ProductQualityReportModel
  ): void {
    this.currentReportDocument = selectedReportDocument;
    this.showReportPDFModal = true;
  }

  private closeReportPDFModal(): void {
    this.showReportPDFModal = false;
  }

  private sortQualityList(issuer: string, key: number): void {
    if (this.sort == "desc") {
      const sortIcon = require("../../../assets/icons/general/asc-sort.svg");
      var relativeIcon = document.getElementById(
        "sort-quality-" + key
      ) as HTMLImageElement;
      relativeIcon.src = sortIcon;
      this.sort = "asc";
      this.docWithQuality.forEach((doc) => {
        if (doc.issuer == issuer) {
          return doc.allCerts.sort(
            (a, b) => Date.parse(a.validToDate) - Date.parse(b.validToDate)
          );
        }
      });
    } else {
      const sortIcon = require("../../../assets/icons/general/desc-sort.svg");
      var relativeIcon = document.getElementById(
        "sort-quality-" + key
      ) as HTMLImageElement;
      relativeIcon.src = sortIcon;
      this.sort = "desc";
      this.docWithQuality.forEach((doc) => {
        if (doc.issuer == issuer) {
          return doc.allCerts.sort(
            (a, b) => Date.parse(b.validToDate) - Date.parse(a.validToDate)
          );
        }
      });
    }
  }

  private openUploadModal(): void {
    this.showUploadModal = true;
  }

  private closeUploadModal(): void {
    this.showUploadModal = false;
  }

  private updateQualityReports(reports: ProductQualityReportModel[]): void {
    this.allQualityReports = reports;
    this.reloadQualityReports(reports);
    this.getQualityReports();
  }

  private getOvalIcon(date: string): string {
    const days = this.getDateDifference(date);
    if (days < 0) {
      return require("../../../assets/icons/general/oval-red.svg");
    } else if (days > 0 && days <= 30) {
      return require("../../../assets/icons/general/oval-orange.svg");
    } else {
      return "";
    }
  }

  private getDateDifference(date: string): number {
    const start = moment(Date.now());
    const end = moment(date);
    const duration = moment.duration(end.diff(start));
    return Math.ceil(duration.asDays());
  }

  private getExpiration(date: string): string {
	const days = this.getDateDifference(date);
	if(days<0){
		return "Expired";
	}else if(days>0 && days<=30) {
		const text = `Expiring in ${days} days`;
		return text;
	} else {
		return "";
	}
	}

	private get uploadQualityPermission(): number {
		return this.userPermissions !== null ? this.userPermissions.uploadQualityReport : 0;
	}

  @Emit()
  private reloadDocuments(): void {}

  @Emit()
  private reloadQualityReports(reports: ProductQualityReportModel[]): void {}
}
