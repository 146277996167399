import { ActionContext, Commit, Dispatch } from 'vuex';
import i18n from '@/i18n';
import { IState } from '../state';
import lodash from "lodash";
import _ from 'underscore';
import Worker from 'web-worker';
import { detect } from 'detect-browser';

// Helpers
import { NotificationHelper } from '@/helpers/notificationHelper';

// Services
import { ClientService } from '@/services/clientService';
import { UserService } from '@/services/userService';
import { MiddlewareService } from '@/services/middlewareService';
import { OrderService } from '@/services/orderService';
import { ComplianceService } from '@/services/complianceService';
import { SupplierService } from '@/services/supplierService';

// Models
import { AgentPilotPermissionReqModel, UserPermissionRequestModel, UserRolePermissionsModel } from '@/models/permissionModel';
import { SocialCertificatesModel } from '@/models/socialCertificatesModel';
import { SustainableCertificatesModel } from '@/models/sustainableCertificatesModel';
import { UserModel } from '@/models/userModel';
import { Guid } from '@/models/guid';
import { BlockchainAccessModal } from '@/models/blockchainAccessModal';
import { TransparencyEffortModal } from '@/models/transparencyEffortModal';
import { FootPrintCompletedConstant } from "@/models/footprintModel";
import { BrandWithSupplierModel } from '@/models/brandWithSupplierModel';
import { CPSCPModel, PartnerRequestModel } from '@/models/supplierPartnerModel';
import { OrderLineStepDisplayModel } from '@/models/orderLineStepDisplayModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { StepState } from '@/models/stepState';
import { OrderCompanyModel } from '@/models/orderCompanyModel';
import { DropdownModel } from '@/models/dropdownModel';
import { CompanyNameModel } from '@/models/companyModel';
import { CertificateModel, CertificateTypes } from '@/models/certificateModel';

export enum ActionTypes {
  SET_MYACCOUNT = 'setMyAccount',
  SET_USER_ROLE_PERMISSIONS = 'setUserRolePermissions',
  SET_ORDER_LOADING = 'setOrderLoading',
  SET_QR_CODE_COUNT = 'setQrCodeCount',
  SET_PENDING_FOOTPRINT_COUNT = 'setPendingFootprintCount',
  SET_TOTAL_STYLE_COUNT = 'setTotalStyleCount',
  SET_SOCIAL_EFFORTS_LOADING = 'setSocialEffortsLoading',
  SET_SOCIAL_EFFORTS = 'setSocialEfforts',
  SET_TRANSPARENCY_EFFORTS = 'setTransparencyEfforts',
  SET_ORDER = 'setOrders',
  SET_DEFAULT_ORDER_STATE = 'setDefaultOrderState',
  SET_ORDER_DETAIL = 'setOrderDetail',
  SET_STEPS = 'setSteps',
  SET_ORDERLINE_STEPS_LOADING = 'setOrderLineStepsLoading',
  SET_ORDERLINE_STEPS = 'setOrderlineSteps',
  SET_WAITINGT1_ORDERLINE_STEPS = 'setWaitingT1OrderlineSteps',
  SET_INPROGRESS_ORDERLINE_STEPS = 'setInprogressOrderlineSteps',
  SET_COMPLETED_ORDERLINE_STEPS = 'setCompletedOrderlineSteps',
  SET_UNIQUE_SUPPLIER_LIST = 'setUniqueSupplierList',
  SET_ALL_ORDERS = 'setAllOrders',
  SET_ALL_ORDERLINE_STEPS = 'setAllOrderlineSteps',
  SET_ALL_TRANSPARENCY_EFFORTS = 'setAllTransparencyEfforts',
  SET_ALL_SOCIAL_EFFORTS = 'setAllSocialEfforts',
  SET_ALL_STYLE_COUNT = 'setAllStyleCount',
  SET_ALL_QR_CODE_COUNT = 'setAllQrCodeCount',
  REMOVE_PENDING_ORDERS = 'removePendingOrders',
  REMOVE_NON_PENDING_ORDRES = 'removeNonPendingOrders',
  SET_DOCUMENTS = 'setDocuments',
  SET_DOCUMENTS_LOADING = 'setDocumentsLoading',
  SET_SENT_ARCHIVE_DOCS = 'setSentArchiveDocs',
  SET_SENT_ARCHIVE_DOCS_LOADING = 'setSentArchiveDocsLoading',
  SET_UNIQUE_SUPPLIERS_LIST_LOADING = 'setUniqueSuppliersListLoading',
  SET_SUPPLIER_CERTIFICATE_LOADING = 'setSuppliersCertificateLoading',
  SET_SUPPLIER_CERTIFICATE = 'setSuppliersCertificate',
  SET_WAITING_T1_ORDER_STEPS_LOADING = 'setWaitingT1OrderStepsLoading',
  SET_INPROGRESS_ORDER_STEPS_LOADING = 'setInprogressOrderStepsLoading',
  SET_COMPLETED_ORDER_STEPS_LOADING = 'setCompletedOrderStepsLoading',
  SET_SUPPLIERS = 'setSuppliers',
  SET_AGENT_PILOT_STATUS = 'setAgentPilotStatus',
  SET_AGENT_PILOT_PERMISSION = 'setAgentPilotPermission',
  SET_ASSOCIATED_PARTNERS = 'setAssociatedPartners',
  SET_T1_ASSOCIATED_PARTNERS = 'setT1AssociatedPartners',
  SET_AGENT_ASSOCIATED_PARTNERS = 'setAgentAssociatedPartners',
  GET_CLIENT_RELATED_SUPPLIERS = 'getClientRelatedSuppliers',
  SET_PARTNERS_WITH_BRANDS = 'setPartnersWithBrands',
  SET_COMPANY_NAMES_LIST = 'setCompanyNameList'
}

export class Actions {
  public static async setMyAccount({
    commit,
    dispatch,
    state,
  }: ActionContext<IState, IState>): Promise<void> {
    if (state.isAppOnline) {
      const service = new ClientService();
      commit(ActionTypes.SET_MYACCOUNT, null);
      try {
        await service
          .getClientAsync()
          .then((response) => {
            if (response !== null) {
              commit(ActionTypes.SET_MYACCOUNT, response);
            }
          })
          .catch((reason) => {
            // console.error('Error while fetching supplier data', reason);
            // NotificationHelper.createErrorNotification(i18n.t('global.notifications.could_not_retrieve_orderlinesteps').toString());
          });
      } catch (reason) {
        console.error('Error while retrieving an order by id', reason);
        NotificationHelper.createErrorNotification(
          i18n
            .t('global.notifications.could_not_retrieve_company_details')
            .toString()
        );
      }
    }
    return Promise.resolve();
  }

  public static async setUserRolePermissions({ commit, state, dispatch }: ActionContext<IState, IState>): Promise<void> {
    const service = new UserService();
    const mwService = new MiddlewareService();
    try{
      let response: BlockchainAccessModal = new BlockchainAccessModal;
      if(state.accessToken !== null){
        response = state.accessToken;
      }
      else{
        response = await service.getBlockchainToken();
        commit('setAccessToken', response);
      }
      if(response !== null){
        const token = response.token;
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const result = JSON.parse(jsonPayload);
        let userPermissions: UserRolePermissionsModel = result.permissions[0];
        let isPilot: boolean = false;
        if (state.user && state.user!.companyType && (state.user.companyType === 1 || state.user.companyType === 5)) {
          if (userPermissions.groupName === 'Admin' && userPermissions.permissions.accessFootprintScreen === undefined) {
            if ( state.user.companyType === 5) {
              var json = require('../../permissions/agentAdminPermission.json');
            } else {
              var json = require('../../permissions/clientAdminPermission.json');
            }
          } else if (userPermissions.groupName === 'Editor' && userPermissions.permissions.accessFootprintScreen === undefined) {
            if ( state.user.companyType === 5) {
              var json = require('../../permissions/agentEditorPermission.json');
            } else {
              var json = require('../../permissions/clientEditorPermissions.json');
            }
          } else if (userPermissions.groupName === 'Reader' && userPermissions.permissions.accessFootprintScreen === undefined) {
            if ( state.user.companyType === 5) {
              var json = require('../../permissions/agentReaderPermission.json');
            } else {
              var json = require('../../permissions/clientReaderPermission.json');
            }
          } else if (userPermissions.groupName === 'Admin' && userPermissions.permissions.accessFootprintScreen !== undefined) {
            isPilot = true;
            if ( state.user.companyType === 5) {
              var json = require('../../permissions/pilotAgentAdminPermission.json');
            } else {
              var json = require('../../permissions/pilotClientAdminPermission.json');
            }
          } else if (userPermissions.groupName === 'Editor' && userPermissions.permissions.accessFootprintScreen !== undefined) {
            isPilot = true;
            if ( state.user.companyType === 5) {
              var json = require('../../permissions/pilotAgentEditorPermission.json');
            } else {
              var json = require('../../permissions/pilotClientEditorPermissions.json');
            }
          } else if (userPermissions.groupName === 'Reader' && userPermissions.permissions.accessFootprintScreen !== undefined) {
            isPilot = true;
            if ( state.user.companyType === 5) {
              var json = require('../../permissions/pilotAgentReaderPermission.json');
            } else {
              var json = require('../../permissions/pilotClientReaderPermission.json');
            }
          }
          if(!_.isEqual(userPermissions.permissions, json.permissions)) {
            const data: UserPermissionRequestModel = new UserPermissionRequestModel;
            data.companyID = userPermissions.companyID;
            data.groupID = userPermissions.groupID;
            data.companyType = state.user !== null ? state.user.companyType : 1;
            data.isPilot = isPilot;
            const permissionResponse = await mwService.updateUserPermission(data);
            if(permissionResponse.result === 'success'){
              let tokenResponse: BlockchainAccessModal = new BlockchainAccessModal;
              tokenResponse = await service.getBlockchainToken();
              commit('setAccessToken', tokenResponse);
              const newToken = tokenResponse.token;
              const newBase64Url = newToken.split('.')[1];
              const newBase64 = newBase64Url.replace(/-/g, '+').replace(/_/g, '/');
              const newJsonPayload = decodeURIComponent(window.atob(newBase64).split('').map(function(c) {
                  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              }).join(''));
              const newResult = JSON.parse(newJsonPayload);
              userPermissions = newResult.permissions[0];
            }
          }
        }
        commit(ActionTypes.SET_USER_ROLE_PERMISSIONS, userPermissions);
      }
    }
    catch(reason) {
      console.error('Error while retrieving an user permission - ', reason);
      NotificationHelper.createErrorNotification(
        i18n
          .t('global.notifications.could_not_retrieve_perimissions')
          .toString()
      );
    }
  }

  public static async setOrderDetail({ commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      const service = new OrderService();
      dispatch(ActionTypes.SET_DEFAULT_ORDER_STATE);
      //TTD-2620, Agent orderlines changes, only condition added
      if (state.companyType === 5) {
        commit(ActionTypes.SET_ORDERLINE_STEPS_LOADING, false);
      }
      if(state.companyNameList.length === 0){
        await dispatch(ActionTypes.SET_COMPANY_NAMES_LIST);
      }
      //TTD-2620, Agent orderlines changes
      return service
      .getOrdersAsync(state.user ? state.user.companyId : '', '', 3000)
      .then(async (response) => {
        if(response){
          dispatch(ActionTypes.SET_ORDERLINE_STEPS);
          let bookmark = response.bookmark;
          let count = response.count;
          const pageSize = 3000;
          commit(ActionTypes.SET_ORDER, response.orders);
          commit(ActionTypes.SET_QR_CODE_COUNT, response.totalQrCodeLive);
          //Sprint-22 TTD-4212
          const provideFootprintCount = response.orders.filter(o => o.footprintFlag === 'TRUE' && (o.finalFootprintStatus === FootPrintCompletedConstant.PARTIAL || o.finalFootprintStatus === "" || o.actionOnFootprint.status === FootPrintCompletedConstant.FORCEINPROGRESS)
          && o.actionOnFootprint.status !== FootPrintCompletedConstant.FORCECOMPLETED).length;
          commit(ActionTypes.SET_PENDING_FOOTPRINT_COUNT, provideFootprintCount);
          commit(ActionTypes.SET_TOTAL_STYLE_COUNT, response.count);
          if(state.companyType === 5){
            dispatch(ActionTypes.SET_AGENT_PILOT_PERMISSION);
          }
          const transperencyEfforts: TransparencyEffortModal[] = [];
          state.orders.forEach(order => {
            const transperency: TransparencyEffortModal = new TransparencyEffortModal;
            if(order.createdAt !== "" && order.createdAt !== undefined && order.createdAt !== null){
              const date = order.createdAt.split("T");
              const splitDate = date[0].split("-");
              transperency.year = splitDate[0];
              if(splitDate[1].charAt(0) === "0") {
                transperency.month = splitDate[1].slice(1);
              } else {
                transperency.month = splitDate[1];
              }
              transperency.date = splitDate[2];
              transperency.orderId = order.orderId;
              transperency.orderNumber = order.orderNumber;
              transperency.pieces = order.orderLines[0].totalQuantityPerCw;
              transperency.styles = 1;
            }
            transperencyEfforts.push(transperency);
            order.fromCompanyName = state.companyNameList.filter(c => c.ID === order.fromCompanyUser.ID).length > 0 ? state.companyNameList.filter(c => c.ID === order.fromCompanyUser.ID)[0].name : order.fromCompanyName;
            order.fromCompanyUser.name = state.companyNameList.filter(c => c.ID === order.fromCompanyUser.ID).length > 0 ? state.companyNameList.filter(c => c.ID === order.fromCompanyUser.ID)[0].name : order.fromCompanyUser.name;
            order.toCompanyName = state.companyNameList.filter(c => c.ID === order.toCompanyId).length > 0 ? state.companyNameList.filter(c => c.ID === order.toCompanyId)[0].name : order.toCompanyName;
            order.agentCompanyName = state.companyNameList.filter(c => c.ID === order.agentCompanyID).length > 0 ? state.companyNameList.filter(c => c.ID === order.agentCompanyID)[0].name : order.agentCompanyName;
            order.delegateCompanyUser.companyName = state.companyNameList.filter(c => c.ID === order.delegateCompanyID).length > 0 ? state.companyNameList.filter(c => c.ID === order.delegateCompanyID)[0].name : order.delegateCompanyUser.companyName;
          });
          commit(ActionTypes.SET_ALL_ORDERS, state.orders);
          commit(ActionTypes.SET_TRANSPARENCY_EFFORTS, transperencyEfforts);
        }
      })
      .catch((reason) => {
        console.error("Error while retrieving an order", reason);
        // NotificationHelper.createErrorNotification(i18n.t('global.notifications.could_not_retrieve_order').toString());
      })
      .finally(() => {
          commit(ActionTypes.SET_ORDER_LOADING, false);
          if(state.suppliers.length <= 1){
            dispatch(ActionTypes.SET_SUPPLIER_CERTIFICATE);
          }
      });
    }
    return Promise.resolve();
  }

  public static async setOrderlineSteps({ commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      const service = new OrderService();
      try{
        commit(ActionTypes.SET_ORDERLINE_STEPS_LOADING, true);
        await service.getAllStepsAsync(state.user && state.user.companyId !== '' ? state.user.companyId : '')
        .then(async (response) => {
         commit(ActionTypes.SET_STEPS, response.orderLineSteps);
        })
      }
      catch(reason){
        console.error("Error while retrieving an order line steps", reason);
      }
      finally{
        if((state.orders.length > 0 || state.orderLineSteps.length > 0) && state.suppliers.length > 0 && (state.brandWithT1SCP.length === 0 && state.brandWithAgent.length === 0 && state.brandWithSCP.length === 0)){
          dispatch(ActionTypes.SET_PARTNERS_WITH_BRANDS);
        }
        if(state.clientRelatedSupplierIds.length === 0 && state.suppliers.length > 0 && (state.t1AssociatedPartners.length > 0 || state.agentAssociatedPartners.length > 0)){
          dispatch(ActionTypes.SET_UNIQUE_SUPPLIER_LIST);
        }
        commit(ActionTypes.SET_ORDERLINE_STEPS_LOADING, false);
      }
    }
  }

  public static async setDocuments({ commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      const service = new ComplianceService();
      const user = state.user as UserModel;
      try{
        commit(ActionTypes.SET_DOCUMENTS_LOADING, true);
        await service.getDocumentsAsync(user.companyId)
          .then(async (response) => {
            commit("setDocuments", response);
            commit(ActionTypes.SET_DOCUMENTS_LOADING, false);
          });
      }
      catch(reason){
        console.error("Error while retrieving documents", reason);
      }
    }
  }

  public static async setSentArchiveDocs({ commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      const service = new ComplianceService();
      try{
        commit(ActionTypes.SET_SENT_ARCHIVE_DOCS_LOADING, true);
        await service.getSentDocumentsAsync()
          .then(async (response) => {
            response.sort((a, b) => {
              return (a.createdAt > b.createdAt) ? -1 : ((a.createdAt < b.createdAt) ? 1 : 0);
            });
            commit("setSentArchiveDocs", response);
            commit(ActionTypes.SET_SENT_ARCHIVE_DOCS_LOADING, false);
          });
      }
      catch(reason){
        console.error("Error while retrieving sent archive docs", reason);
      }
    }
  }

  public static async setUniqueSupplierList({commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      if(state.clientRelatedSupplierIds.length === 0){
        const browser: any = detect();
        if(browser.name === 'safari'){
          let suppliers = state.suppliers;
          let orders = state.orders;
          let allSteps = state.orderLineSteps;
          let allSuppliers = [];
          let uniqueSuppliers: string[] = [];
          let steps: OrderLineStepDisplayModel[] = [];
          allSteps = allSteps.filter(s => s.stepState !== 3 && s.stepState !== 6 && s.stepState !== 9 && s.stepState !== 12 && s.stepState !== 0 && s.toCompanyId !== "00000000-0000-0000-0000-000000000000" && s.toCompanyId !== "");
          orders.forEach(o => {
              steps.push(...allSteps.filter(s => s.orderId === o.orderId));
          });
          suppliers = suppliers.filter(s => s.isTier1 === false);
          allSuppliers = steps.map(s => s.toCompanyId);
          allSuppliers = lodash.uniq(allSuppliers);
          suppliers.forEach(s => {
            if(uniqueSuppliers.indexOf(s.id) === -1){
                uniqueSuppliers.push(s.id);
            }
          });
          commit("setClientRelatedSupplierIds", uniqueSuppliers);
          dispatch(ActionTypes.GET_CLIENT_RELATED_SUPPLIERS);
        } else {
          const worker = new Worker(`data:,function getUniqSuppliers(data){
            let clientData = JSON.parse(data);
            let suppliers = clientData.suppliers;
            let orders = clientData.orders;
            let allSteps = clientData.steps;
            let allSuppliers = [];
            let uniqueSuppliers = [];
            let steps = [];
            allSteps = allSteps.filter(s => s.stepState !== 3 && s.stepState !== 6 && s.stepState !== 9 && s.stepState !== 12 && s.stepState !== 0 && s.toCompanyId !== "00000000-0000-0000-0000-000000000000" && s.toCompanyId !== "");
            orders.forEach(o => {
                steps.push(...allSteps.filter(s => s.orderId === o.orderId));
            });
            suppliers = suppliers.filter(s => s.isTier1 === false);
            allSuppliers = steps.map(s => s.toCompanyId);
            allSuppliers.forEach(sup => {
                if(uniqueSuppliers.indexOf(sup) === -1){
                    uniqueSuppliers.push(sup);
                }
            });
            suppliers.forEach(s => {
              if(uniqueSuppliers.indexOf(s.id) === -1){
                  uniqueSuppliers.push(s.id);
              }
            });
            return JSON.stringify(uniqueSuppliers);
          }
          onmessage = function(event){    //This will be called when worker.postMessage is called in the outside code.
              let data = event.data;    //Get the argument that was passed from the outside code, in this case data.
              let result = getUniqSuppliers(data);    //Find the result. This will take long time but it doesn't matter since it's called in the worker.
              postMessage(result);    //Send the result to the outside code.
          };`);
          worker.onmessage = function(event: any){    //Get the result from the worker. This code will be called when postMessage is called in the worker.
            let uniqueSuppliers = JSON.parse(event.data);
            commit("setClientRelatedSupplierIds", uniqueSuppliers);
            dispatch(ActionTypes.GET_CLIENT_RELATED_SUPPLIERS);
          }
          let clientData = {
            orders: state.orders,
            steps: state.orderLineSteps,
            suppliers: state.suppliers
          }
          let data = JSON.stringify(clientData);
          worker.postMessage(data);
        }
      }
    }
  }

  public static async setPartnersWithBrands({commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      let brands: DropdownModel[] | undefined = [];
      if(state.user?.topBrands !== null && state.user?.topBrands !== undefined && state.user?.topBrands.length > 0){
          brands = state.user?.topBrands.map(b => new DropdownModel(b.brandName, b.brandName));
      }
      else{
        if(state.company === null){
          await dispatch(ActionTypes.SET_MYACCOUNT);
        }
        brands = state.company?.topBrands.map(brand => new DropdownModel(brand.brandName, brand.brandName));
      }
      const browser: any = detect();
      if(state.user?.companyType === 1){
        if(state.brandWithT1SCP.length === 0 && state.brandWithAgent.length === 0 && state.brandWithSCP.length === 0){
          if(browser.name === 'safari'){
            let orders = state.orders;
            let allSteps = state.orderLineSteps;
            let suppliers = state.suppliers;
            let brandWithT1SCP: BrandWithSupplierModel[] = [];
            let brandWithAgent: BrandWithSupplierModel[] = [];
            let brandWithSCP: BrandWithSupplierModel[] = [];
            brands?.forEach(brand => {
              const ordersWithBrand = orders.filter(o => (o.brandName === brand.value));
              let cpIDs: string[] = [];
              let agentIDs: string[] = [];
              ordersWithBrand.forEach(order => {
                if(cpIDs.indexOf(order.toCompanyId) === -1){
                  cpIDs.push(order.toCompanyId);
                }
                if(agentIDs.indexOf(order.agentCompanyID) === -1){
                  agentIDs.push(order.agentCompanyID);
                }
              });
              brandWithT1SCP.push({ brandName: brand.text, IDs: cpIDs });
              brandWithAgent.push({ brandName: brand.text, IDs: agentIDs });
              brandWithSCP.push({ brandName: brand.text, IDs: [] });
            })
            const NPs = suppliers.filter(s => s.isAgent === false && s.isTier1 === false);
            NPs.forEach(np => {
                const npIDs: string[] = [];
                const stepsWithNP = allSteps.filter(o => o.toCompanyId === np.id);
                if(stepsWithNP.length > 0 && npIDs.indexOf(stepsWithNP[0].toCompanyId) === -1){
                  npIDs.push(stepsWithNP[0].toCompanyId);
                  stepsWithNP.forEach(step => {
                    const order = orders.filter(o => o.orderId === step.orderId);
                    if(order.length > 0){
                        const brandIndex = brandWithSCP.findIndex(b => b.brandName === order[0].brandName);
                        if(brandIndex > -1){
                          if(brandWithSCP[brandIndex].IDs.indexOf(np.id) === -1){
                            brandWithSCP[brandIndex].IDs.push(np.id);
                          }
                        }
                    }
                })
              }
            })
            commit("setBrandWithT1SCP", brandWithT1SCP);
            commit("setBrandWithAgent", brandWithAgent);
            commit("setBrandWithSCP", brandWithSCP);
            commit(ActionTypes.SET_SUPPLIER_CERTIFICATE_LOADING, false);
          } else {
            const worker = new Worker(`data:,function getPartnersWithBrands(data){
              let clientData = JSON.parse(data);
              let orders = clientData.orders;
              let allSteps = clientData.steps;
              let suppliers = clientData.suppliers;
              let brands = clientData.brands;
              let brandWithT1SCP = [];
              let brandWithAgent = [];
              let brandWithSCP = [];
              brands.forEach(brand => {
                const ordersWithBrand = orders.filter(o => (o.brandName === brand.value));
                let cpIDs = [];
                let agentIDs = [];
                ordersWithBrand.forEach(order => {
                  if(cpIDs.indexOf(order.toCompanyId) === -1){
                    cpIDs.push(order.toCompanyId);
                  }
                  if(agentIDs.indexOf(order.agentCompanyID) === -1){
                    agentIDs.push(order.agentCompanyID);
                  }
                });
                brandWithT1SCP.push({ brandName: brand.text, IDs: cpIDs });
                brandWithAgent.push({ brandName: brand.text, IDs: agentIDs });
                brandWithSCP.push({ brandName: brand.text, IDs: [] });
              })
              const NPs = suppliers.filter(s => s.isAgent === false && s.isTier1 === false);
              NPs.forEach(np => {
                  const npIDs = [];
                  const stepsWithNP = allSteps.filter(o => o.toCompanyId === np.id);
                  if(stepsWithNP.length > 0 && npIDs.indexOf(stepsWithNP[0].toCompanyId) === -1){
                    npIDs.push(stepsWithNP[0].toCompanyId);
                    stepsWithNP.forEach(step => {
                      const order = orders.filter(o => o.orderId === step.orderId);
                      if(order.length > 0){
                          const brandIndex = brandWithSCP.findIndex(b => b.brandName === order[0].brandName);
                          if(brandIndex > -1){
                            if(brandWithSCP[brandIndex].IDs.indexOf(np.id) === -1){
                              brandWithSCP[brandIndex].IDs.push(np.id);
                            }
                          }
                      }
                  })
                }
              })
              let brandPartners = {
                brandWithT1SCP: brandWithT1SCP,
                brandWithAgent: brandWithAgent,
                brandWithSCP: brandWithSCP
              }
              return JSON.stringify(brandPartners);
            }
            onmessage = function(event){    //This will be called when worker.postMessage is called in the outside code.
                let data = event.data;    //Get the argument that was passed from the outside code, in this case data.
                let result = getPartnersWithBrands(data);    //Find the result. This will take long time but it doesn't matter since it's called in the worker.
                postMessage(result);    //Send the result to the outside code.
            };`);
            worker.onmessage = function(event: any){    //Get the result from the worker. This code will be called when postMessage is called in the worker.
              let brandPartners = JSON.parse(event.data);
              commit("setBrandWithT1SCP", brandPartners.brandWithT1SCP);
              commit("setBrandWithAgent", brandPartners.brandWithAgent);
              commit("setBrandWithSCP", brandPartners.brandWithSCP);
              commit(ActionTypes.SET_SUPPLIER_CERTIFICATE_LOADING, false);
            }
            let clientData = {
              orders: state.orders,
              steps: state.orderLineSteps,
              suppliers: state.suppliers,
              brands: brands
            }
            let data = JSON.stringify(clientData);
            worker.postMessage(data);
          }
        } else {
          commit(ActionTypes.SET_SUPPLIER_CERTIFICATE_LOADING, false);
        }
      } else {
        if(state.brandWithT1SCP.length === 0 && state.brandWithAgent.length === 0 && state.brandWithSCP.length === 0){
          if(browser.name === 'safari'){
            let orders = state.orders;
            let allSteps = state.orderLineSteps;
            let suppliers = state.suppliers;
            let brandWithSCP: BrandWithSupplierModel[] = [];
            brands?.forEach(brand => {
              brandWithSCP.push({ brandName: brand.text, IDs: [] });
            })
            const NPs = suppliers.filter(s => s.isAgent === false && s.isTier1 === false);
            NPs.forEach(np => {
                const npIDs:string[] = [];
                const stepsWithNP = allSteps.filter(o => o.toCompanyId === np.id);
                if(stepsWithNP.length > 0 && npIDs.indexOf(stepsWithNP[0].toCompanyId) === -1){
                  npIDs.push(stepsWithNP[0].toCompanyId);
                  stepsWithNP.forEach(step => {
                    const order = orders.filter(o => o.orderId === step.orderId);
                    if(order.length > 0){
                        const brandIndex = brandWithSCP.findIndex(b => b.brandName === order[0].brandName);
                        if(brandIndex > -1){
                          if(brandWithSCP[brandIndex].IDs.indexOf(np.id) === -1){
                            brandWithSCP[brandIndex].IDs.push(np.id);
                          }
                        }
                    }
                })
              }
            })
            commit("setBrandWithT1SCP", []);
            commit("setBrandWithAgent", []);
            commit("setBrandWithSCP", brandWithSCP);
            commit(ActionTypes.SET_SUPPLIER_CERTIFICATE_LOADING, false);
          } else {
            const worker = new Worker(`data:,function getPartnersWithBrands(data){
              let clientData = JSON.parse(data);
              let orders = clientData.orders;
              let allSteps = clientData.steps;
              let suppliers = clientData.suppliers;
              let brands = clientData.brands;
              let brandWithSCP = [];
              brands.forEach(brand => {
                brandWithSCP.push({ brandName: brand.text, IDs: [] });
              })
              const NPs = suppliers.filter(s => s.isAgent === false && s.isTier1 === false);
              NPs.forEach(np => {
                  const npIDs = [];
                  const stepsWithNP = allSteps.filter(o => o.toCompanyId === np.id);
                  if(stepsWithNP.length > 0 && npIDs.indexOf(stepsWithNP[0].toCompanyId) === -1){
                    npIDs.push(stepsWithNP[0].toCompanyId);
                    stepsWithNP.forEach(step => {
                      const order = orders.filter(o => o.orderId === step.orderId);
                      if(order.length > 0){
                          const brandIndex = brandWithSCP.findIndex(b => b.brandName === order[0].brandName);
                          if(brandIndex > -1){
                            if(brandWithSCP[brandIndex].IDs.indexOf(np.id) === -1){
                              brandWithSCP[brandIndex].IDs.push(np.id);
                            }
                          }
                      }
                  })
                }
              })
              let brandPartners = {
                brandWithSCP: brandWithSCP
              }
              return JSON.stringify(brandPartners);
            }
            onmessage = function(event){    //This will be called when worker.postMessage is called in the outside code.
                let data = event.data;    //Get the argument that was passed from the outside code, in this case data.
                let result = getPartnersWithBrands(data);    //Find the result. This will take long time but it doesn't matter since it's called in the worker.
                postMessage(result);    //Send the result to the outside code.
            };`);
            worker.onmessage = function(event: any){    //Get the result from the worker. This code will be called when postMessage is called in the worker.
              let brandPartners = JSON.parse(event.data);
              commit("setBrandWithT1SCP", []);
              commit("setBrandWithAgent", []);
              commit("setBrandWithSCP", brandPartners.brandWithSCP);
              commit(ActionTypes.SET_SUPPLIER_CERTIFICATE_LOADING, false);
            }
            let clientData = {
              orders: state.orders,
              steps: state.orderLineSteps,
              suppliers: state.suppliers,
              brands: brands
            }
            let data = JSON.stringify(clientData);
            worker.postMessage(data);
          }
        } else {
          commit(ActionTypes.SET_SUPPLIER_CERTIFICATE_LOADING, false);
        }
      }
    }
  }

  public static async removePendingOrders({commit, state, dispatch}: ActionContext<IState, IState>, orderIds: string[]): Promise<void> {
    if(state.isAppOnline){
      const orders = state.orders;
      const transparency = state.transparencyEfforts;
      await orderIds.forEach(id => {
        const index = orders.findIndex(o => o.orderId === id);
        if(index !== -1){
          orders.splice(index, 1);
          if(state.totalStyles > 0){
            commit(ActionTypes.SET_ALL_STYLE_COUNT, state.totalStyles - 1);
          }
          const transparencyIndex = transparency.findIndex(t => t.orderId === id);
          if(transparencyIndex !== -1){
            transparency.splice(transparencyIndex, 1);
          }
        }
      });
      commit(ActionTypes.SET_ALL_ORDERS, orders);
      commit(ActionTypes.SET_ALL_TRANSPARENCY_EFFORTS, transparency);
    }
  }

  public static async removeNonPendingOrders({commit, state, dispatch}: ActionContext<IState, IState>, orderIds: string[]): Promise<void> {
    if(state.isAppOnline){
      const orders = state.orders;
      const steps = state.orderLineSteps;
      await orderIds.forEach(id => {
        const index = orders.findIndex(o => o.orderId === id);
        if(index !== -1){
          orders.splice(index, 1);
        }
        const idRelatedSteps = steps.filter(s => s.orderId === id);
        if(idRelatedSteps.length > 0){
          idRelatedSteps.forEach(step => {
            const stepIndex = steps.findIndex(s => s.id === step.id);
            steps.splice(stepIndex, 1);
          });
        }
      });
      commit(ActionTypes.SET_ALL_ORDERS, orders);
      commit(ActionTypes.SET_ALL_ORDERLINE_STEPS, steps);
    }
  }

  public static async setSuppliersCertificate({commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      const service = new ClientService();
        commit(ActionTypes.SET_SUPPLIER_CERTIFICATE_LOADING, true);
        return await service.getSuppliersListAsync(state.user && state.user.companyId ? state.user.companyId : '')
        .then((response) => {
          commit(ActionTypes.SET_SUPPLIER_CERTIFICATE, response.getT1SCP);
          commit(ActionTypes.SET_SUPPLIERS, response.supplier);
        })
        .catch((reason) => {
          console.error("Error while fetching supplier certificates", reason);
          // NotificationHelper.createErrorNotification(i18n.t('global.notifications.could_not_retrieve_supplier_certificate').toString());
        })
        .finally(() => {
          if((state.orders.length > 0 || state.orderLineSteps.length > 0) && state.suppliers.length > 0 && (state.brandWithT1SCP.length === 0 && state.brandWithAgent.length === 0 && state.brandWithSCP.length === 0)){
            dispatch(ActionTypes.SET_PARTNERS_WITH_BRANDS);
          } else {
            if(state.brandWithT1SCP.length > 0 || state.brandWithAgent.length > 0 || state.brandWithSCP.length > 0){
              commit(ActionTypes.SET_SUPPLIER_CERTIFICATE_LOADING, false);
            } else {
              commit(ActionTypes.SET_SUPPLIER_CERTIFICATE_LOADING, false);
            }
          }
          if(state.t1AssociatedPartners.length === 0 && state.agentAssociatedPartners.length === 0 && state.user?.companyType !== 5) {
            dispatch(ActionTypes.SET_ASSOCIATED_PARTNERS);
          }
        });
    }
  }

  public static async setAssociatedPartners({commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      const service = new ClientService();
      try{
        const cpList: PartnerRequestModel = {
          t1SCPList: state.suppliers.filter(s => s.isTier1 === true || s.isAgent === true).map(p => p.id)
        }
        if(cpList.t1SCPList.length > 0){
          await service.getPartners(cpList)
            .then(async (response) => {
              const t1SCPPartners: CPSCPModel[] = [];
              const agentPartners: CPSCPModel[] = [];
              const contractualPartners = state.suppliers.filter(s => s.isTier1 === true);
              const agents = state.suppliers.filter(s => s.isAgent === true);
              // T1SCP partners
              contractualPartners.forEach(cp => {
                const t1Partners = response.t1SCPpartners.find(p => p.companyID === cp.id);
                if(t1Partners !== undefined){
                  t1SCPPartners.push(t1Partners);
                }
              });
              commit(ActionTypes.SET_T1_ASSOCIATED_PARTNERS, t1SCPPartners);
              // Agent partners
              agents.forEach(ag => {
                const agentPartner = response.t1SCPpartners.find(p => p.companyID === ag.id);
                if(agentPartner !== undefined){
                  agentPartners.push(agentPartner);
                }
              });
              commit(ActionTypes.SET_AGENT_ASSOCIATED_PARTNERS, agentPartners);
            })
            .finally(() => {
                if(state.orders.length > 0 && state.orderLineStepsLoading === false && state.orderLineSteps.length === 0){
                  dispatch(ActionTypes.SET_ORDERLINE_STEPS);
                } else {
                  if(state.clientRelatedSupplierIds.length === 0 && state.orderLineStepsLoading === false && state.orderLineSteps.length > 0){
                    dispatch(ActionTypes.SET_UNIQUE_SUPPLIER_LIST);
                  }
                }
            })
        } else {
          commit(ActionTypes.SET_T1_ASSOCIATED_PARTNERS, []);
          commit(ActionTypes.SET_AGENT_ASSOCIATED_PARTNERS, []);
        }
      }
      catch(reason){
        console.error("Error while retrieving associated partners", reason);
      }
    }
  }

  public static async setSocialEfforts({commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      commit(ActionTypes.SET_SOCIAL_EFFORTS_LOADING, true);
      const service = new OrderService();
      const confectioningSteps = state.orderLineSteps.filter(s => s.stepId === 10);
      const confectioningSupplierIds = confectioningSteps.map(c => c.toCompanyId);
      let uniqueSupplierIds = lodash.uniq(confectioningSupplierIds);
      uniqueSupplierIds = uniqueSupplierIds.filter(id => id !== Guid.Empty);
      await service.getSocialEffort(uniqueSupplierIds, state.user !== null ? state.user.companyId : '')
        .then((response) => {
          commit(ActionTypes.SET_ALL_SOCIAL_EFFORTS, response.socialEfforts);
        })
        .catch((reason) => {
          console.error("Error while fetching social efforts", reason);
          // NotificationHelper.createErrorNotification(i18n.t('global.notifications.could_not_retrieve_social_efforts').toString());
        })
        .finally(() => {
          commit(ActionTypes.SET_SOCIAL_EFFORTS_LOADING, false);
        });
    }
  }

  public static async setAgentPilotPermission({commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      const mwService = new MiddlewareService();
      if(state.orders.findIndex(o => o.footprintFlag === "TRUE") !== -1){
        commit(ActionTypes.SET_AGENT_PILOT_STATUS, true);
        if(state.userPermissions?.accessFootprintScreen === undefined || state.userPermissions?.accessFootprintScreen === 0){
          const token = state.accessToken !== null ? state.accessToken.token : '';
          const base64Url = token.split('.')[1];
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          const result = JSON.parse(jsonPayload);
          let userPermissions: UserRolePermissionsModel = result.permissions[0];
          const data: AgentPilotPermissionReqModel = {
            companyID: userPermissions.companyID,
            groupID: userPermissions.groupID,
            companyType: "5",
            footprintFlag: "TRUE"
          }
          const updatePermResponse = await mwService.updateAgentPilotPermission(data);
          if(updatePermResponse.result === "success"){
            commit('setAccessToken', null);
            dispatch(ActionTypes.SET_USER_ROLE_PERMISSIONS);
          }
        }
      }
      else{
        commit(ActionTypes.SET_AGENT_PILOT_STATUS, false);
        if(state.userPermissions?.accessFootprintScreen !== undefined){
          const token = state.accessToken !== null ? state.accessToken.token : '';
          const base64Url = token.split('.')[1];
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          const result = JSON.parse(jsonPayload);
          let userPermissions: UserRolePermissionsModel = result.permissions[0];
          const data: AgentPilotPermissionReqModel = {
            companyID: userPermissions.companyID,
            groupID: userPermissions.groupID,
            companyType: "5",
            footprintFlag: "FALSE"
          }
          const updatePermResponse = await mwService.updateAgentPilotPermission(data);
          if(updatePermResponse.result === "success"){
            commit('setAccessToken', null);
            dispatch(ActionTypes.SET_USER_ROLE_PERMISSIONS);
          }
        }
      }
    }
  }

  public static async getClientRelatedSuppliers( {commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    const service = new ClientService();
    if(state.user !== null){
      commit(ActionTypes.SET_UNIQUE_SUPPLIERS_LIST_LOADING, true);
      await service.setSuppliersListAsync(state.clientRelatedSupplierIds, state.user.companyId)
      .then((result) => {
        if(result){
          commit(ActionTypes.SET_UNIQUE_SUPPLIERS_LIST_LOADING, false);
          const social: CertificateModel[] = [];
          const sustainable: CertificateModel[] = [];
          const clientPartners: DropdownModel[] = [];
          result.company.forEach(res=>{
              res.sustainability.forEach(sus=>{
                  sus.toCompanyID = res.ID;
                  sus.companyID = res.companyID;
                  sus.companyName = res.name;
              });
              res.social.forEach(soc=>{
                  soc.toCompanyID = res.ID;
                  soc.companyID = res.companyID;
                  soc.companyName = res.name;
              });
              social.push(...res.social);
              sustainable.push(...res.sustainability);
              clientPartners.push(new DropdownModel(res.ID, res.name));
          });
          result.t1ScpCompany.forEach(res=>{
              res.sustainability.forEach(sus=>{
                  sus.toCompanyID = res.ID;
                  sus.companyID = res.companyID;
                  sus.companyName = res.name;
              });
              res.social.forEach(soc=>{
                  soc.toCompanyID = res.ID;
                  soc.companyID = res.companyID;
                  soc.companyName = res.name;
              });
              social.push(...res.social);
              sustainable.push(...res.sustainability);
              clientPartners.push(new DropdownModel(res.ID, res.name));
          });

          const finalSuntainability: CertificateModel[] = [];
          const finalSocial: CertificateModel[] = [];

          sustainable.forEach(doc => {
              if(finalSuntainability.findIndex(d => d.ID === doc.ID && d.companyID === doc.companyID) === -1){
                  doc.isShowArchive = false;
                  doc.relatableType = CertificateTypes.SUSTAINABILITY;
                  doc.isArchiving = false;
                  doc.expiryInfo = '';
                  doc.expiryStatus = '';
                  finalSuntainability.push(doc);
              }
          });
          social.forEach(docSoc => {
              if(finalSocial.findIndex(d => d.ID === docSoc.ID && d.companyID === docSoc.companyID) === -1){
                docSoc.isShowArchive = false;
                docSoc.relatableType = CertificateTypes.SOCIAL;
                docSoc.isArchiving = false;
                docSoc.expiryInfo = '';
                docSoc.expiryStatus = '';
                finalSocial.push(docSoc);
              }
          });
          commit("setSocialCertificates", finalSocial);
          commit("setSustainableCertificate", finalSuntainability);
          commit("setClientPartners", clientPartners);
        }
      });
    }
    if(state.t1AssociatedPartners.length === 0 && state.agentAssociatedPartners.length === 0) {
      dispatch(ActionTypes.SET_ASSOCIATED_PARTNERS);
    }
  }

  public static async setCompanyNameList({commit, state, dispatch}: ActionContext<IState, IState>): Promise<void> {
    if(state.isAppOnline){
      const service = new SupplierService();
      await service.getCompanyNameAsync()
        .then((response) => {
          commit(ActionTypes.SET_COMPANY_NAMES_LIST, response);
        })
        .catch((reason) => {
          console.error("Error while fetching company names", reason);
          // NotificationHelper.createErrorNotification(i18n.t('global.notifications.could_not_retrieve_social_efforts').toString());
        })
    }
  }

  public static setDefaultOrderState( {commit, dispatch}: ActionContext<IState, IState>): void {
    commit(ActionTypes.SET_ORDER_LOADING, true);
    commit(ActionTypes.SET_ORDERLINE_STEPS_LOADING, true);
    commit(ActionTypes.SET_SUPPLIER_CERTIFICATE_LOADING, true);
    commit(ActionTypes.SET_WAITING_T1_ORDER_STEPS_LOADING, true);
    commit(ActionTypes.SET_INPROGRESS_ORDER_STEPS_LOADING, true);
    commit(ActionTypes.SET_COMPLETED_ORDER_STEPS_LOADING, true);
    commit(ActionTypes.SET_SOCIAL_EFFORTS_LOADING, true);
    commit(ActionTypes.SET_ALL_QR_CODE_COUNT, 0);
    commit(ActionTypes.SET_ALL_STYLE_COUNT, 0);
    commit(ActionTypes.SET_ALL_SOCIAL_EFFORTS, []);
    commit(ActionTypes.SET_ALL_TRANSPARENCY_EFFORTS, []);
    commit(ActionTypes.SET_ALL_ORDERS, []);
    commit(ActionTypes.SET_ALL_ORDERLINE_STEPS, []);
    commit(ActionTypes.SET_SUPPLIERS, []);
  }
}
